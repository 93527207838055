export const identityServerEndPoints = {
  login: `${process.env.REACT_APP_IDDS_IDENTITY_SERVER_URL}/`,
  redirect: `${process.env.REACT_APP_IDDS_IDENTITY_SERVER_REDIRECT_URL}/Callback`,
  postLogoutRedirect: `${process.env.REACT_APP_IDDS_IDENTITY_SERVER_REDIRECT_URL}`,
};

export const dealerPortalEndPoints = {
  getOrganizationKey: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/orgid/`,
  getOrganization: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/organization/`,
  getUserDetails: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/user/`,
  addUserDetails: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/user/`,
  deleteUserDetails: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/user/`,
  getSubscriptions: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/organization/`,
  getVehicleHistoryReport: `${process.env.REACT_APP_IDDS_INTEGRATION_API_URL}/shl/dp/report/`,
  getSubscriptionStatus: `${process.env.REACT_APP_IDDS_INTEGRATION_API_URL}/shl/dp/subscription/`,
  sessionInfo: `${process.env.REACT_APP_IDDS_ADMIN_API_URL}/shl/dp/usersession/`,
};
