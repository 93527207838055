import {
  GET_REPORT_ACTION_FAILED,
  GET_REPORT_ACTION_SUCCESS,
} from "./../actionTypes/welcomeActionTypes";
import axios from "axios";
import { dealerPortalEndPoints } from "../Constants/apiConstants";
import { GET_USERDETAILS_SUCCESS } from "./../actionTypes/loginActionTypes";
import {
  GENERATE_REPORT_IN_PROGRESS,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
} from "./../actionTypes/welcomeActionTypes";
import { UseAuthorization } from "../utils/UseAuthorization";
import { logoutUser } from "./loginAction";

export const getUserDetails = () => {
  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  return async (dispatch) => {
    return await axios
      .get(
        `${dealerPortalEndPoints.getUserDetails}${
          localStorageData.userName + "@" + localStorageData.organization
        }`,
        UseAuthorization()
      )
      .then(async (response) => {
        if (response.data && response.data.length > 0) {
          response.data[0].employee = `${response.data[0].userDisplay}`;
        }
        await dispatch(
          getUserDetailsSuccess(
            response.data.length > 0 ? response.data[0] : []
          )
        );
        return response.data;
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
};

export const getSubscriptionDetails = () => {
  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  return async (dispatch) => {
    return await axios
      .get(
        `${dealerPortalEndPoints.getSubscriptionStatus}${localStorageData.dealerNumber}`,
        UseAuthorization()
      )
      .then(async (response) => {
        await dispatch(getSubscriptionDetailsSuccess(response.data));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
};

export const getSubscriptionDetailsSuccess = (response) => {
  return (dispatch) => {
    dispatch({ type: GET_SUBSCRIPTION_DETAILS_SUCCESS, payload: response });
  };
};

export const getUserDetailsSuccess = (response) => {
  return (dispatch) => {
    dispatch({ type: GET_USERDETAILS_SUCCESS, payload: response });
  };
};

export const getVehicleHistoryReport = (
  callbackFunc,
  dealerNumber,
  vin,
  userKey
) => {
  return async (dispatch) => {
    dispatch({ type: GENERATE_REPORT_IN_PROGRESS });
    const meta = UseAuthorization();
    meta.headers["Content-Type"] = "application/json";
    meta.headers["Accept"] = "application/pdf";
    meta.responseType = "blob";
    await axios
      .get(
        `${dealerPortalEndPoints.getVehicleHistoryReport}${dealerNumber}/${vin}/${userKey}`,
        meta
      )
      .then((response) => {
        const pdf = new Blob([response.data], { type: "application/pdf" });
        const reportURL = URL.createObjectURL(pdf);
        const newWindow = window.open();
        newWindow.location.href = reportURL;
        callbackFunc();
        dispatch(getSubscriptionDetails());
        dispatch({ type: GET_REPORT_ACTION_SUCCESS });
      })
      .catch((error) => {
        callbackFunc();
        if (!error.response) {
          dispatch(getVehicleHistoryReportFailed_DueToNetworkIssue());
        } else {
          dispatch(getVehicleHistoryReportFailed());
        }
      });
  };
};

export const getVehicleHistoryReportFailed = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REPORT_ACTION_FAILED,
      payload: "VIN not available at this time",
    });
  };
};

export const getVehicleHistoryReportFailed_DueToNetworkIssue = () => {
  return (dispatch) => {
    dispatch({
      type: GET_REPORT_ACTION_FAILED,
      payload: "Communication Failed",
    });
  };
};
