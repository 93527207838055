import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import {
  LOGIN_ACTION_SUCCESS,
  LOGIN_ACTION_CANCELLED,
  LOGIN_ACTION_FAILED,
  GET_ORGANIZATION_SUCCESS,
  GET_USERDETAILS_SUCCESS,
  USER_CREDENTIALS,
  LOGIN_ACTION,
} from "./../actionTypes/loginActionTypes";
import { USER_FOUND } from "redux-oidc";
import { dealerPortalEndPoints } from "../Constants/apiConstants";
import { UseAuthorization } from "../utils/UseAuthorization";

const INITIAL_STATE = {
  token: "",
  access_token: "",
  username: "",
  orgName: "",
  loginerror: false,
  errormsg: "",
  organization: "",
  userDetails: null,
  userCredentials: null,
  login_in_process: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      return { ...state, login_in_process: true };
    case LOGIN_ACTION_FAILED:
      return {
        ...state,
        loginerror: true,
        message: action.payload,
        login_in_process: false,
      };
    case LOGIN_ACTION_CANCELLED:
    case LOGIN_ACTION_SUCCESS:
      return { ...state, login_in_process: false };
    case USER_FOUND:
      if (localStorage.getItem("session_key_stored") !== "true") {
        const sessionKey = uuidv4();
        localStorage.setItem("session_key", sessionKey);
        axios.put(
          dealerPortalEndPoints.sessionInfo,
          {
            userKey: action.payload.profile?.user_key,
            sessionKey: sessionKey,
          },
          UseAuthorization()
        );
        localStorage.setItem("session_key_stored", "true");
      }
      localStorage.setItem("dp_user_id_token", action.payload.id_token);
      return {
        ...state,
        token: action.payload.id_token,
        access_token: action.payload.access_token,
        username: action.payload.profile?.user_key,
        orgName: action.payload.profile?.store_alias,
      };
    case GET_ORGANIZATION_SUCCESS:
      return { ...state, organization: action.payload };
    case GET_USERDETAILS_SUCCESS:
      return { ...state, userDetails: action.payload };
    case USER_CREDENTIALS:
      return { ...state, userCredentials: action.payload };
    default:
      return state;
  }
};
