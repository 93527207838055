import React from "react";
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import moment from "moment";

interface ISubscriptionInformation {
  tableData: any;
  reportLimitMet: boolean;
  subscriptionExpired: boolean;
}

const SubscriptionInformation = (props: ISubscriptionInformation) => {
  const { tableData, reportLimitMet, subscriptionExpired } = props;

  const dateFormat = (date) => {
    const renewalDate = moment(date).format("M/D/YYYY");
    return renewalDate;
  };

  const showErrorColor = (isRed) => {
    return isRed ? "#cf2a27" : null;
  };

  return (
    <>
      {tableData === null ? null : (
        <KendoGrid data={[tableData]} className={"condensedKendoGrid"}>
          <Column
            field="subscriptionRenewalDate"
            title="Subscription Renewal Date"
            cell={(GridCellProps) => {
              return (
                <td style={{ color: showErrorColor(subscriptionExpired) }}>
                  {dateFormat(GridCellProps.dataItem.subscriptionRenewalDate)}
                </td>
              );
            }}
          />
          <Column field="reportsAllowed" title="Number of Reports Allowed" />
          <Column
            field="reportsRun"
            title="Number of Reports Run"
            cell={(GridCellProps) => {
              return (
                <td style={{ color: showErrorColor(reportLimitMet) }}>
                  {GridCellProps.dataItem.reportsRun}
                </td>
              );
            }}
          />
        </KendoGrid>
      )}
    </>
  );
};

export default SubscriptionInformation;
