import React from "react";
import { Grid } from "@material-ui/core";

const ForgotPasswordReminder = (_props) => {
  return (
    <Grid item xs={12}>
      <div
        style={{
          backgroundColor: "#eef6fc",
          borderRadius: "4px",
          padding: "1.25em 1.5em",
          borderColor: "#3298dc",
          borderStyle: "solid",
          borderWidth: "0 0 0 4px",
        }}
      >
        If you forgot your username or password, check with your system
        administrator or call{" "}
        <b>HDDS Business Development 1-800-428-5090 Opt. 1</b>.
      </div>
    </Grid>
  );
};

export default ForgotPasswordReminder;
