import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import configureStore from "./store/configStore";
import { OidcProvider } from "redux-oidc";
import userManager from "./utils/authUtil";
import { loadUser } from "redux-oidc";
import { BrowserRouter } from "react-router-dom";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
const store = configureStore();
const theme = createMuiTheme();

theme.overrides = {
  MuiTypography: {
    body1: {
      fontSize: "1.6rem",
    },
    h6: {
      fontSize: "2rem",
    },
  },
  MuiButton: {
    text: {
      fontSize: "1.4rem",
    },
  },
};

loadUser(store, userManager);
ReactDOM.render(
  <Provider store={store}>
    <OidcProvider userManager={userManager} store={store}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </OidcProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
