import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginUser, isUserLoggedIn } from "../../action/loginAction";
import {
  CircularProgress,
  InputAdornment,
  IconButton,
  Button,
  Divider,
  Paper,
  CssBaseline,
} from "@material-ui/core";
import { TextField, Grid, Container, Typography } from "@material-ui/core";
import { LoginStyles } from "./Login.styles";
import { hddsStyles } from "../../core/styles/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import clsx from "clsx";
import PageHeader from "../pageheader/PageHeader";

const useStyles = LoginStyles;
const globalStyle = hddsStyles;

const Login = (props) => {
  const classes = useStyles();
  const globalClasses = globalStyle();
  const dispatch = useDispatch();

  const { message, loading } = useSelector((state) => {
    return {
      error: state.login.loginerror,
      message: state.login.message,
      loading: state.login.login_in_process,
    };
  });

  const [userDetails, setUserDetails] = useState({
    userName: "",
    password: "",
    dealerNumber: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const existingUser = () => {
    const user = dispatch(isUserLoggedIn());
    setUserDetails({
      userName: user.payload.userName,
      password: "",
      dealerNumber: user.payload.dealerNumber,
    });
  };

  const login = () => {
    dispatch(
      loginUser(
        userDetails.userName,
        userDetails.password,
        userDetails.dealerNumber,
        setUserDetails
      )
    );
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  useEffect(() => {
    existingUser();
  }, []);

  return (
    <>
      <PageHeader userDetails={userDetails} showUser={false} />
      <CssBaseline />
      <Container maxWidth="sm" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={globalClasses.pageTitleText}>
              Log In
            </Typography>
            <Divider
              className={globalClasses.headerDividerStyle}
              style={{ marginBottom: "15px" }}
            />
            <form onSubmit={(event) => event.preventDefault()}>
              <Paper
                className={globalClasses.paper}
                style={{ paddingTop: "2rem" }}
              >
                <Grid container spacing={2}>
                  <Grid container item xs={12}>
                    <TextField
                      label="Dealer Number"
                      name="dealerNumber"
                      className={clsx(
                        globalClasses.requiredFormField,
                        globalClasses.textField
                      )}
                      value={userDetails.dealerNumber}
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                      autoFocus
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      label="Username"
                      name="userName"
                      className={clsx(
                        globalClasses.textField,
                        globalClasses.requiredFormField
                      )}
                      value={userDetails.userName}
                      variant="outlined"
                      onChange={handleChange}
                      fullWidth
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <TextField
                      label="Password"
                      name="password"
                      className={clsx(
                        globalClasses.requiredFormField,
                        globalClasses.textField
                      )}
                      type={showPassword ? "text" : "password"}
                      variant="outlined"
                      value={userDetails.password}
                      onChange={handleChange}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle passwodd visibility"
                              onClick={handleClickShowPassword}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid container item xs={12}>
                    <div
                      className={classes.fieldContainer}
                      style={{ justifyContent: "center" }}
                    >
                      <Typography
                        className={classes.errorMessage}
                        variant="h5"
                        style={{ justifyContent: "center" }}
                      >
                        {message}
                      </Typography>
                    </div>
                  </Grid>
                </Grid>
                <Divider light />
                <div className={classes.loginFooter}>
                  <div className={globalClasses.loginFooterText}>
                    Forgot Username or Password?{" "}
                  </div>
                  <div className={globalClasses.loginFooterText}>
                    Please see your dealership security administrator with
                    authority to manage these credentials.
                  </div>
                </div>
              </Paper>
              <br />
              {!loading ? (
                <Button
                  className={globalClasses.confirmationButton}
                  disabled={
                    !userDetails.userName ||
                    !userDetails.password ||
                    !userDetails.dealerNumber ||
                    loading
                  }
                  type="submit"
                  onClick={() => login()}
                >
                  Log In
                </Button>
              ) : (
                <Button className={globalClasses.confirmationButton}>
                  <CircularProgress
                    size={24}
                    className={globalClasses.buttonProgress}
                  />
                  Logging In...
                </Button>
              )}
            </form>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default Login;
