import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import ForgotPasswordReminder from "./forgot-password-reminder";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const LoggingIn = (_props) => {
  const globalClasses = globalStyle();
  return (
    <div>
      <PageHeader page={"logging_in"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Logging In
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12}>
                <Typography className={globalClasses.pageSectionTitle}>
                  Logging In
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                To access the Service History-Link report you will need to log
                in using the credentials provided by your system administrator.
              </Grid>
              <Grid item xs={12}>
                <img src="assets/images/login.png" />
              </Grid>
              <Grid item xs={12}>
                <ol>
                  <li>Enter your Dealer Number.</li>
                  <li>Enter your Username.</li>
                  <li>Enter your Password.</li>
                  <li>
                    Select <b>Log In</b>.
                  </li>
                </ol>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                All fields are required and must be associated with a valid
                subscription.
              </Grid>
              <ForgotPasswordReminder />
              <br />
              <Grid item xs={12}>
                <Typography className={globalClasses.pageSectionTitle}>
                  Logging Out
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                If you are finished running SHL reports, you can exit your
                session by selecting <b>Log Out</b>. If you do not log out, your
                session will remain active, even if you close your browser. It
                is recommended that if you use a shared workstation, that you
                log out after each session.
              </Grid>
              <Grid item xs={12}>
                <ol>
                  <li>
                    Select the user icon in the upper right of the screen
                    <br />
                    <img src="assets/images/user_actions_collapsed.png" />
                  </li>
                  <li>
                    Select logout from the menu
                    <br />
                    <img src="assets/images/user_actions_expanded.png" />
                  </li>
                </ol>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LoggingIn;
