import React, { useEffect, useReducer } from "react";
import "primereact/resources/themes/nova-light/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Login from "./components/login/Login";
import Callback from "./components/login/Callback";
import Welcome from "./components/welcome/Welcome";
import ManageUser from "./components/manageuser/ManageUser";
import AddUser from "./components/AddUser/AddUser";
import Toast from "./utils/shared/Toasts";
import PrivateRoute from "./PrivateRoute";
import { createBrowserHistory } from "history";
import EditUser from "./components/AddUser/EditUser";
import ServiceHistoryLink from "./components/help/service-history-link";
import LoggingIn from "./components/help/logging-in";
import GeneratingReport from "./components/help/generating-a-report";
import ServiceHistoryLinkReport from "./components/help/service-history-link-report";
import CPOInterpretation from "./components/help/cpo-interpretation";
import ManagingYourSubscription from "./components/help/managing-your-subscription";
import AddingUsers from "./components/help/adding-users";
import { hddsStyles } from "./core/styles/styles";
import "../src/core/styles/kendo-overrides.scss";
import PageFooter from "../src/components/pagefooter/PageFooter";

const globalStyle = hddsStyles;

const App: React.FC = () => {
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const id_token = localStorage.getItem("dp_user_id_token");

  const location = useLocation();

  const history = createBrowserHistory({ forceRefresh: true });

  const globalClasses = globalStyle();

  useEffect(() => {
    localStorage.setItem("dp_path", history.location.pathname);
  }, [location]);

  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (
        e.key &&
        (e.key === "dp_user_id_token" ||
          e.key === "dp_userDetails" ||
          e.key.startsWith("oidc."))
      ) {
        if (e.oldValue !== e.newValue) {
          forceUpdate();
        }
        if (e.key && e.key === "dp_user_id_token" && e.newValue == null) {
          sessionStorage.clear();
        }
      } else if (e.key && e.key === "dp_path") {
        if (
          e.oldValue != "/Callback" &&
          e.newValue != "/Callback" &&
          e.oldValue !== e.newValue
        ) {
          history.push(e.newValue);
        }
      }
    });
  }, []);

  return (
    <div className={globalClasses.contentWorkspace}>
      <Switch>
        <Route
          exact
          path="/"
          render={(props) =>
            !id_token ? (
              <Login {...props} />
            ) : (
              <Redirect
                to={{ pathname: "/welcome", state: { from: props.location } }}
              />
            )
          }
        ></Route>
        <Route exact path="/callback" component={Callback} />
        <PrivateRoute authed={!!id_token} path="/welcome" component={Welcome} />
        <PrivateRoute authed={!!id_token} path="/adduser" component={AddUser} />
        <PrivateRoute
          authed={!!id_token}
          path="/edituser/:id"
          component={EditUser}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/manageuser"
          component={ManageUser}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Service_History-Link"
          component={ServiceHistoryLink}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Service_History-Link.htm"
          component={ServiceHistoryLink}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Logging_In"
          component={LoggingIn}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Generating_a_Report"
          component={GeneratingReport}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Service_History-Link_Report"
          component={ServiceHistoryLinkReport}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/CPO_Interpretation"
          component={CPOInterpretation}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Managing_Your_Subscription"
          component={ManagingYourSubscription}
        />
        <PrivateRoute
          authed={!!id_token}
          path="/help/Adding_Users"
          component={AddingUsers}
        />
        <Route exact path="/help">
          <Redirect to={"/help/Service_History-Link"}></Redirect>
        </Route>
        <Route path="/**">
          <Redirect to={"/welcome"} />
        </Route>
      </Switch>
      {<Toast></Toast>}
      <div className={globalClasses.footer}>
        <PageFooter />
      </div>
    </div>
  );
};

export default App;
