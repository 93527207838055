export enum LoginResponseMessages {
  USER_LOCKED = "user is locked.",
  USER_IS_LOCKING = "user failed authentication. login attempts remaining = 0.",
  USER_LAST_ATTEMPT = "user failed authentication. login attempts remaining = 1.",
}

export enum LoginHelperText {
  LOCK_WARNING_TEXT = "Your account is at risk of being locked.",
  USER_LOCKED_TEXT = "Your account has been locked. Please see your dealership security administrator.",
  DEFAULT_MESSAGE = "Valid Dealer Number, Username, Password, and Subscription are required",
}
