import React, { useState, useEffect } from "react";
import { AddUserStyles } from "./AddUser.styles";
import PageHeader from "../pageheader/PageHeader";
import {
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Paper,
  List,
  CircularProgress,
  CssBaseline,
  Container,
  Radio,
  FormHelperText,
  Box,
  Button,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";
import * as yup from "yup";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import { InputAdornment } from "@material-ui/core";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { addUser } from "../../action/manageUserAction";
import { dealerPortalEndPoints } from "../../Constants/apiConstants";
import { ADD_USER } from "../../actionTypes/manageUserActionTypes";
import { UseAuthorization } from "../../utils/UseAuthorization";
import clsx from "clsx";
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  orderBy,
  filterBy,
} from "@progress/kendo-data-query";
import { UserMessages } from "../../enums/usermessages";

const useStyles = AddUserStyles;
const globalStyle = hddsStyles;

const AddUser = (props) => {
  const classes = useStyles();
  const { isEdit } = props;
  const { users } = useSelector((state) => state.managedUsers);

  let { userDetails } = useSelector((state) => {
    return {
      userDetails: state.login.userDetails,
    };
  });

  const globalClasses = globalStyle();
  const alphaNumeric = /^[a-zA-Z0-9.]+$/gi;
  const charsAndSpaces = /^[a-zA-Z ]+$/gi;
  const [dealerOrg, setDealerOrg] = useState(null);
  const dispatch = useDispatch();
  const { selectedUser, add_user_in_process } = useSelector(
    (state) => state.managedUsers
  );
  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  const [showPassword, setShowPassword] = useState(false);
  const [gridData, setGridData] = React.useState([]);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>({
    logic: "and",
    filters: [],
  });
  const [sorter, setSorter] = React.useState<SortDescriptor[]>([
    { field: "employee", dir: "asc" },
  ]);
  const [userStores, setUserStores] = useState([]);
  const [gridErrorText, setGridErrorText] = useState("");

  const selectStores = () => {
    axios
      .get(
        `${dealerPortalEndPoints.getSubscriptions}${localStorageData.dealerNumber}?byDealer=true`,
        UseAuthorization()
      )
      .then((response) => {
        const data = (response.data?.dealers || []).map((item) => {
          return {
            userKey: "",
            storename: item.dealerName,
            subscription: item.subscriptionDescription,
            orgKey: item.dealerNumber,
            orgDisplay: item.dealerName,
            storeSubscriptionDescription: item.subscriptionDescription,
            isSelectedStore: false,
            isDefaultOrg: false,
          };
        });
        setDealerOrg(data || []);
        subscriptionInfo(data);
      })
      .catch((err) => console.log(err));
  };

  const subscriptionInfo = (data: any) => {
    const userOrgs = [];

    if (selectedUser && selectedUser?.userDealerMappings) {
      data.forEach((x) => {
        if (selectedUser.userDealerMappings.length >= 1) {
          selectedUser?.userDealerMappings.forEach((y) => {
            if (y.orgKey === x.orgKey) {
              x.isSelectedStore = true;
              x.isDefaultOrg = y.isDefaultOrg;
            }
          });
        }
        userOrgs.push(x);
      });
    } else {
      data.forEach((x) => userOrgs.push(x));
    }

    setUserStores(userOrgs);
    setGridData(userOrgs);
  };

  useEffect(() => {
    selectStores();
  }, []);

  useEffect(() => {
    if (dealerOrg) {
      setGridData(orderBy(filterBy([...userStores], filter), sorter));
    }
  }, [filter, sorter]);

  const handleStoreSelectCheck = (rowData) => {
    for (const org of userStores) {
      if (org.orgKey === rowData.orgKey) {
        if (org.isDefaultOrg) return;
        if (userStores.filter((x) => x.isDefaultOrg).length === 0) {
          org.isDefaultOrg = true;
        }
        org.isSelectedStore = !org.isSelectedStore;
        break;
      }
    }
    setGridErrorText("");
    setGridData([...userStores]);
  };

  const handleDefaultSelect = (rowData) => {
    for (const org of userStores) {
      if (org.orgKey === rowData.orgKey) {
        org.isDefaultOrg = true;
        org.isSelectedStore = true;
      } else org.isDefaultOrg = false;
    }
    setGridErrorText("");
    setGridData([...userStores]);
  };

  const initialValues = {
    password: "",
    confirmPassword: "",
    userDisplay: selectedUser ? selectedUser.userDisplay : "",
    username: selectedUser ? selectedUser.username : "",
    admin: selectedUser ? selectedUser.admin : false,
    userLocked: selectedUser ? selectedUser.userLocked : false,
  };

  const listItems = [
    "Minimum of 7 characters",
    "Contains one upper case letter",
    "Contains one lower case letter",
    "Contains one numeric character",
    "Contains one symbol [!,*,@,#,$,%,^,&,+,=]",
    "Cannot include Username, or any part of Full Name",
    "Cannot be one of your 4 previous passwords",
  ];

  const listItemsAdd = [
    "Minimum of 7 characters",
    "Contains one upper case letter",
    "Contains one lower case letter",
    "Contains one numeric character",
    "Contains one symbol [!,*,@,#,$,%,^,&,+,=]",
    "Cannot include Username, or any part of Full Name",
  ];

  const passwordList = isEdit ? listItems : listItemsAdd;

  const validationSchema = yup.object().shape({
    userDisplay: yup
      .string()
      .min(2, "Too Short!")
      .max(55, "Too Long!")
      .matches(charsAndSpaces, "Name can take only characters")
      .required("Name is required"),
    username: yup
      .string()
      .min(4, "Too Short!")
      .max(30, "Too Long!")
      .matches(
        alphaNumeric,
        "Username must be 4-30 letters and or numbers no spaces"
      )
      .required("Username is required"),
    password: yup
      .string()
      .min(7, "Too Short!")
      .max(40, "Too Long!")
      //.required("Password is required")
      .test("test-password", "Please provide a valid Password", (value) => {
        if (selectedUser && !value) return true;
        return Boolean(value) && passwordValidation(value);
      })
      .when(["userDisplay"], {
        is: (userDisplay) => Boolean(userDisplay),
        then: yup.string().when("userDisplay", (userDisplay, schema) => {
          return schema.test({
            test: (password) => {
              const splitVals = userDisplay.split(" ");
              if (selectedUser && password === undefined) return true; // if manage users window didn't change the PW
              for (let i = 0; i < splitVals.length; i++) {
                const value = splitVals[i].toString().toLowerCase();
                if (password?.toLowerCase().includes(value)) return false;
              }
              return true; // getting here means no name tokens appeared, so we're OK
            },
            message: "Password should not include parts of full name",
          });
        }),
      })
      .when(["username"], {
        is: (username) => Boolean(username),
        then: yup.string().when("username", (username, schema) => {
          return schema.test({
            test: (password) => {
              if (selectedUser && password === undefined) return true;
              return (
                password &&
                password?.toLowerCase().indexOf(username.toLowerCase()) === -1
              );
            },
            message: "Password should not include username",
          });
        }),
      }),
    confirmPassword: yup
      .string()
      .min(7, "Too Short!")
      .test(
        "test-confirmPassword",
        "Please provide a valid Password",
        (value) => {
          if (selectedUser) return true;
          return Boolean(value) && passwordValidation(value);
        }
      )
      .when(["password"], {
        is: (password) => Boolean(password),
        then: yup
          .string()
          .oneOf([yup.ref("password")], "Password does not match"),
      }),
  });

  const passwordValidation = (password) => {
    let valid = true;
    valid =
      valid &&
      Boolean(
        password.match(
          /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!*@#$%^&+=])([a-zA-Z\d!*@#$%^&+=]+){7,}$/
        )
      );
    return valid;
  };

  const renderErrorMessage = () => {
    return (
      <>
        {gridErrorText === "" ? null : (
          <FormHelperText className={globalClasses.formHelperTextError}>
            {gridErrorText}
          </FormHelperText>
        )}
      </>
    );
  };

  const handleSubmit = (values, { resetForm }) => {
    if (isEdit) {
      const admins = users.filter((user) => user.admin);
      if (
        admins?.length < 1 &&
        admins[0]?.userKey === selectedUser?.userKey &&
        !values?.admin
      ) {
        setGridErrorText(UserMessages.ADMIN_REQ);
        return;
      }
    }
    if (!userDetails) {
      userDetails = JSON.parse(localStorage.getItem("dp_userDetails"));
      userDetails.orgKey = userDetails.orgKey
        ? userDetails.orgKey
        : userDetails.organization;
    }

    const selectedStores = userStores?.filter((item) => item.isSelectedStore);

    if (!userStores || selectedStores.length < 1) {
      setGridErrorText(UserMessages.DEALER_REQ);
      return;
    }

    const defaultStore = selectedStores.filter((item) => item.isDefaultOrg);
    if (defaultStore.length < 1) {
      setGridErrorText(UserMessages.DEFUALT_STORE);
      return;
    }

    const payload = {
      userKey: `${values.username}@${userDetails?.orgKey}`,
      orgKey: userDetails?.orgKey,
      userName: values.username,
      userDisplay: values.userDisplay,
      password: values.password,
      isSHLAdmin: values.admin,
      userIsLocked: values.userLocked ? true : false,
      userDealerMappings: selectedStores.map((item) => {
        return {
          isDefaultOrg: item.isDefaultOrg,
          orgDisplay: item.orgDisplay,
          orgKey: item.orgKey,
          storeSubscription: item.storeSubscriptionDescription,
          userKey: `${values.username}@${userDetails?.orgKey}`,
        };
      }),
    };

    const formCleanUp = () => {
      resetForm();
      (dealerOrg || []).forEach((item) => {
        item.selected = false;
        item.default = false;
      });
      props.history.push("/manageuser");
    };
    const isUpdateUser = selectedUser ? true : false;
    if (isUpdateUser && payload.password === "") {
      delete payload.password;
    }
    dispatch({ type: ADD_USER }); //For loading icon on Save
    dispatch(addUser(payload, formCleanUp, isUpdateUser));
  };

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: handleSubmit,
  });

  const isSaveEnabled = (values) => {
    if (isEdit && (!values.userDisplay || !values.username)) return true;
    if (
      !isEdit &&
      (!values.userDisplay ||
        !values.username ||
        !values.password ||
        !values.confirmPassword)
    )
      return true;
    return false;
  };

  return (
    <div>
      <PageHeader
        userDetails={userDetails}
        showUser={true}
        page={isEdit ? "edit_user" : "add_user"}
      />
      <CssBaseline />
      <Container maxWidth="lg" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              {isEdit ? "User Profile" : "Add User"}
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit}>
              <Paper className={globalClasses.pagePaper}>
                <Grid container item xs={12}>
                  <Grid item md={6} xs={12} style={{ paddingRight: "1rem" }}>
                    <Grid item xs={12}>
                      <Typography className={globalClasses.pageSectionTitle}>
                        Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="userDisplay"
                        label="Full Name"
                        name="userDisplay"
                        autoFocus
                        className={clsx(
                          formik.touched.userDisplay &&
                            Boolean(formik.errors.userDisplay)
                            ? globalClasses.errorFormField
                            : globalClasses.requiredFormField,
                          globalClasses.textField,
                          classes.customField
                        )}
                        value={formik.values.userDisplay}
                        inputProps={{ maxLength: 55 }}
                        onChange={formik.handleChange}
                        variant="outlined"
                        fullWidth
                      />
                      <FormHelperText
                        className={globalClasses.formHelperTextError}
                      >
                        {formik.touched.userDisplay &&
                          Boolean(formik.errors.userDisplay) &&
                          formik.touched.userDisplay &&
                          formik.errors.userDisplay}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={globalClasses.pageSectionTitle}>
                        Login Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="outlined-required-input"
                        label="Username"
                        className={clsx(
                          isEdit
                            ? globalClasses.disabledFormField
                            : formik.touched.username &&
                              Boolean(formik.errors.username)
                            ? globalClasses.errorFormField
                            : globalClasses.requiredFormField,
                          globalClasses.textField,
                          classes.customField
                        )}
                        name="username"
                        value={formik.values.username}
                        inputProps={{ maxLength: 30 }}
                        onChange={formik.handleChange}
                        variant="outlined"
                        InputProps={{ readOnly: selectedUser }}
                      />
                      <FormHelperText
                        className={globalClasses.formHelperTextError}
                      >
                        {formik.touched.username &&
                          Boolean(formik.errors.username) &&
                          formik.touched.username &&
                          formik.errors.username}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={globalClasses.pageSectionTitle}>
                        Admin
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                      <FormControlLabel
                        className={globalClasses.checkboxControl}
                        control={
                          <Checkbox
                            checked={formik.values.admin}
                            onChange={formik.handleChange}
                            name="admin"
                            size="small"
                          />
                        }
                        label="User is allowed to add users and change passwords for other users."
                      ></FormControlLabel>
                      {selectedUser && (
                        <FormControlLabel
                          className={globalClasses.checkboxControl}
                          control={
                            <Checkbox
                              checked={formik.values.userLocked}
                              onChange={formik.handleChange}
                              name="userLocked"
                              size="small"
                            />
                          }
                          label="Account Locked"
                        />
                      )}
                    </Grid>
                  </Grid>
                  <Grid item md={6} xs={12} style={{ paddingLeft: "1rem" }}>
                    <Grid item xs={12}>
                      <Typography className={globalClasses.pageSectionTitle}>
                        Password
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider light />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="password"
                        label="Password"
                        className={clsx(
                          formik.touched.password &&
                            Boolean(formik.errors.password)
                            ? globalClasses.errorFormField
                            : globalClasses.requiredFormField,
                          globalClasses.textField,
                          classes.customField
                        )}
                        name="password"
                        inputProps={{ maxLength: 40 }}
                        type={showPassword ? "text" : "password"}
                        margin="normal"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handlePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText
                        className={globalClasses.formHelperTextError}
                      >
                        {formik.touched.password &&
                          Boolean(formik.errors.password) &&
                          formik.touched.password &&
                          formik.errors.password}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="confirmPassword"
                        label="Confirm Password"
                        className={clsx(
                          formik.touched.confirmPassword &&
                            Boolean(formik.errors.confirmPassword)
                            ? globalClasses.errorFormField
                            : globalClasses.requiredFormField,
                          globalClasses.textField,
                          classes.customField
                        )}
                        type={showPassword ? "text" : "password"}
                        name="confirmPassword"
                        inputProps={{ maxLength: 40 }}
                        variant="outlined"
                        value={formik.values.confirmPassword}
                        onChange={formik.handleChange}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={handlePasswordVisibility}
                            >
                              {showPassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                      <FormHelperText
                        className={globalClasses.formHelperTextError}
                      >
                        {formik.touched.confirmPassword &&
                          Boolean(formik.errors.confirmPassword) &&
                          formik.touched.confirmPassword &&
                          formik.errors.confirmPassword}
                      </FormHelperText>
                    </Grid>
                    <Grid item xs={12} className={classes.listContainer}>
                      <List style={{ listStyleType: "disc" }}>
                        {passwordList.map((item) => (
                          <li key={item}>{item}</li>
                        ))}
                      </List>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container item xs={12} style={{ marginTop: "1rem" }}>
                  <Grid item xs={12}>
                    <Typography className={globalClasses.pageSectionTitle}>
                      Select User Subscriptions
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {renderErrorMessage()}
                  </Grid>
                  <Grid item xs={12}>
                    {!dealerOrg ? (
                      <div className={globalClasses.centeredText}>
                        <Box position="relative" display="inline-flex">
                          <CircularProgress
                            className={clsx(globalClasses.spinnerSecondary)}
                            size={60}
                          />
                          <Box
                            top={0}
                            left={0}
                            bottom={0}
                            right={0}
                            position="absolute"
                            display="flex"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Typography
                              variant="caption"
                              component="div"
                              color="textSecondary"
                            >
                              Loading
                            </Typography>
                          </Box>
                        </Box>
                      </div>
                    ) : (
                      <KendoGrid
                        sortable
                        filterable
                        reorderable
                        data={gridData}
                        filter={filter}
                        onFilterChange={(e) => {
                          setFilter(e.filter);
                        }}
                        sort={sorter}
                        onSortChange={(e) => {
                          setSorter(e.sort);
                        }}
                        className={"condensedKendoGrid"}
                      >
                        <Column
                          width="50px"
                          orderIndex={0}
                          filterable={false}
                          sortable={false}
                          resizable={false}
                          cell={(GridCellProps) => {
                            return (
                              <td>
                                <Checkbox
                                  name="selected"
                                  checked={
                                    GridCellProps.dataItem.isSelectedStore
                                  }
                                  className={globalClasses.gridCheckbox}
                                  onChange={() =>
                                    handleStoreSelectCheck(
                                      GridCellProps.dataItem
                                    )
                                  }
                                />
                              </td>
                            );
                          }}
                        />
                        <Column field="orgDisplay" title="Store Name" />
                        <Column
                          width="75px"
                          orderIndex={0}
                          filterable={false}
                          sortable={false}
                          resizable={false}
                          title="Default"
                          cell={(GridCellProps) => {
                            return (
                              <td>
                                <Radio
                                  name="default"
                                  checked={GridCellProps.dataItem.isDefaultOrg}
                                  className={globalClasses.gridRadio}
                                  onChange={() =>
                                    handleDefaultSelect(GridCellProps.dataItem)
                                  }
                                />
                              </td>
                            );
                          }}
                        />
                        <Column field="orgKey" title="Subscription" />
                      </KendoGrid>
                    )}
                  </Grid>
                </Grid>
              </Paper>
              {!add_user_in_process ? (
                <Button
                  className={globalClasses.confirmationButton}
                  type="submit"
                  disabled={isSaveEnabled(formik.values)}
                >
                  Save
                </Button>
              ) : (
                <Button className={globalClasses.confirmationButton}>
                  <CircularProgress
                    className={globalClasses.buttonProgress}
                    size={24}
                  />
                  Save
                </Button>
              )}
              <Button
                className={globalClasses.cancelButton}
                onClick={() => {
                  props.history.push("/manageuser");
                }}
              >
                Cancel
              </Button>
            </form>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddUser;
