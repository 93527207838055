import {
  LOGIN_ACTION_FAILED,
  LOGIN_ACTION_CANCELLED,
  LOGIN_ACTION,
} from "./../actionTypes/loginActionTypes";
import axios from "axios";
import userManager from "../utils/authUtil";
import {
  dealerPortalEndPoints,
  identityServerEndPoints,
} from "../Constants/apiConstants";
import { LoginHelperText } from "../enums/loginmessages";
import { UseAuthorization } from "../utils/UseAuthorization";

export const loginUser = (userkey, password, dealernum, setUserDetails) => {
  return async (dispatch) => {
    localStorage.removeItem("dp_userDetails");
    localStorage.removeItem("dp_user_id_token");
    localStorage.removeItem("session_key");
    localStorage.removeItem("session_key_stored");
    let orgKey = "";
    let isSubscriptionActive = false;

    dispatch({ type: LOGIN_ACTION });
    await axios
      .get(`${dealerPortalEndPoints.getSubscriptionStatus}${dealernum}`)
      .then((response) => {
        isSubscriptionActive = response.data.isSubscriptionActive;
        if (!isSubscriptionActive) {
          dispatch(loginFailed());
        }
      })
      .catch(() => {
        dispatch(loginFailed());
      });

    if (isSubscriptionActive) {
      await axios
        .get(`${dealerPortalEndPoints.getOrganizationKey}${dealernum}?mode=0`)
        .then((response) => {
          const userCredentials = {
            userName: userkey,
            dealerNumber: dealernum,
            organization: response.data,
          };
          localStorage.setItem(
            "dp_userDetails",
            JSON.stringify(userCredentials)
          );
          orgKey = response.data;
        });

      await axios
        .get(
          `${
            dealerPortalEndPoints.sessionInfo
          }${userkey}@${orgKey.toLowerCase()}`
        )
        .then((response) => {
          if (response.data.isLoggedIn === false) {
            signin(dealernum, userkey, orgKey, password);
          } else {
            if (
              window.confirm(
                "Username already logged in. Click OK to exit other session and log in here."
              )
            ) {
              axios
                .delete(
                  `${
                    dealerPortalEndPoints.sessionInfo
                  }${userkey}@${orgKey.toLowerCase()}`
                )
                .then((_) => {
                  signin(dealernum, userkey, orgKey, password);
                });
            } else {
              localStorage.removeItem("dp_userDetails");
              localStorage.removeItem("dp_user_id_token");
              localStorage.removeItem("session_key");
              localStorage.removeItem("session_key_stored");
              setUserDetails({ userName: "", password: "", dealerNumber: "" });
              dispatch(loginCancelled());
            }
          }
        })
        .catch((error) => {
          console.log("get here3?");
          localStorage.removeItem("dp_userDetails");
          localStorage.removeItem("dp_user_id_token");
          localStorage.removeItem("session_key");
          localStorage.removeItem("session_key_stored");
          if (!error.response) {
            dispatch(loginFailed(LoginHelperText.DEFAULT_MESSAGE));
          } else {
            dispatch(loginFailed());
          }
        });
    }
  };
};

export const signin = (
  dealerNum: string,
  userName: string,
  orgKey: string,
  password: string
) => {
  userManager.signinRedirect({
    extraQueryParams: {
      prompt: "login",
      loginType: "externalQuickLogin",
      orgKey: orgKey.toLowerCase(),
      serverDatastores: [],
      user: `{UserKey: '${userName}@${orgKey.toLowerCase()}', Password: '${password}', DealerNum: '${dealerNum}', StoreAlias: 'SHL'}`,
    },
  });
};

export const loginFailed = (message?: string) => {
  return {
    type: LOGIN_ACTION_FAILED,
    payload: message ? message : LoginHelperText.DEFAULT_MESSAGE,
  };
};

export const loginCancelled = () => {
  return {
    type: LOGIN_ACTION_CANCELLED,
  };
};

export const isUserLoggedIn = () => {
  const user = JSON.parse(localStorage.getItem("dp_userDetails"));
  if (user != null) {
    user["userExists"] = true;
    return {
      type: {},
      payload: user,
    };
  } else {
    return {
      type: {},
      payload: {
        username: "",
        password: "",
        dealerNumber: "",
        userExists: false,
      },
    };
  }
};

export const logoutUser = () => {
  return async (dispatch) => {
    const user = JSON.parse(localStorage.getItem("dp_userDetails"));
    axios
      .delete(
        `${dealerPortalEndPoints.sessionInfo}${user["userName"]}@${user[
          "organization"
        ].toLowerCase()}`,
        UseAuthorization()
      )
      .then((_response) => {
        const token = localStorage.getItem("dp_user_id_token");
        localStorage.removeItem("dp_userDetails");
        localStorage.removeItem("dp_user_id_token");
        localStorage.removeItem("session_key");
        localStorage.removeItem("session_key_stored");
        sessionStorage.clear();
        userManager.clearStaleState();
        userManager.removeUser().catch((reason) => {
          console.error(reason);
        });
        userManager
          .signoutRedirect({
            id_token_hint: token,
            post_logout_redirect_uri:
              identityServerEndPoints.postLogoutRedirect,
          })
          .catch((error) => console.log(error));
      });
  };
};
