import React from "react";
import { useHistory } from "react-router-dom";
import { hddsStyles } from "../../core/styles/styles";
import {
  Typography,
  AppBar,
  Toolbar,
  Menu,
  MenuItem,
  IconButton,
  MenuList,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";

const globalStyle = hddsStyles;

const HelpPageHeader = (props) => {
  const globalClasses = globalStyle();
  const history = useHistory();

  const handleImageClick = () => {
    history.push("/welcome");
  };
  const handleTitleClick = () => {
    history.push("/help/Service_History-Link");
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <AppBar position="absolute">
        <Toolbar className={globalClasses.toolbar}>
          <img
            className={globalClasses.logo}
            src={"/assets/images/Harley-DavidsonNBG.png"}
            onClick={handleImageClick}
          />
          <Typography className={globalClasses.title}>
            <span style={{ cursor: "pointer" }} onClick={handleTitleClick}>
              SHL Help
            </span>
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            aria-label="open drawer"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={globalClasses.helpMenu}
          >
            <MenuList style={{ padding: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "larger" }}>
                SHL Report
              </span>
              <MenuItem
                onClick={() => history.push("/help/Generating_a_Report")}
                selected={props.page === "gen_report"}
              >
                Generating a Report
              </MenuItem>
              <MenuItem
                onClick={() =>
                  history.push("/help/Service_History-Link_Report")
                }
                selected={props.page === "shl_report"}
              >
                Service History-Link Report
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/help/CPO_Interpretation")}
                selected={props.page === "cpo_inter"}
              >
                CPO Interpretation
              </MenuItem>
            </MenuList>
            <MenuList style={{ padding: "10px" }}>
              <span style={{ fontWeight: "bold", fontSize: "larger" }}>
                SHL Subscription
              </span>
              <MenuItem
                onClick={() => history.push("/help/Logging_In")}
                selected={props.page === "logging_in"}
              >
                Logging In
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/help/Managing_Your_Subscription")}
                selected={props.page === "manage_sub"}
              >
                Managing Your Subscription
              </MenuItem>
              <MenuItem
                onClick={() => history.push("/help/Adding_Users")}
                selected={props.page === "add_user"}
              >
                Adding and Editing Users
              </MenuItem>
            </MenuList>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default HelpPageHeader;
