export function UseAuthorization(config = null) {
  let authToken;
  const sessionData = sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_IDDS_IDENTITY_SERVER_URL}/:external-dealer-portal`
  );

  if (sessionData) {
    const parsedData = JSON.parse(sessionData);
    authToken = parsedData.access_token;
  }

  return {
    headers: !authToken ? {} : { Authorization: `Bearer ${authToken}` },
  };
}
