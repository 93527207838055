import React, { useState, useEffect } from "react";
import PageHeader from "../pageheader/PageHeader";
import {
  Container,
  Grid,
  TextField,
  Typography,
  Divider,
  CssBaseline,
  FormHelperText,
  Paper,
  CircularProgress,
  Backdrop,
  Button,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";
import { useSelector, useDispatch } from "react-redux";
import {
  getVehicleHistoryReport,
  getUserDetails,
  getSubscriptionDetails,
} from "../../action/welcomeAction";
import SubscriptionInformation from "./SubscriptionInformation";
import clsx from "clsx";
import { UserMessages } from "../../enums/usermessages";
import moment from "moment";
import axios from "axios";
import { dealerPortalEndPoints } from "../../Constants/apiConstants";
import { UseAuthorization } from "../../utils/UseAuthorization";
import { logoutUser } from "../../action/loginAction";

const globalStyle = hddsStyles;

const Welcome = (props) => {
  const globalClasses = globalStyle();
  const dispatch = useDispatch();
  const [details, setDetails] = useState({
    vin: "",
  });
  const [subscriptionExpired, setSubscriptionExpired] =
    useState<boolean>(false);
  const [reportLimitMet, setReportLimitMet] = useState<boolean>(false);
  const welcome = useSelector((state) => state.welcome);
  const today = new Date();
  const zoneDate = moment.parseZone(today.toISOString()).local().format();

  useEffect(() => {
    dispatch(getUserDetails());
    dispatch(getSubscriptionDetails());
  }, []);

  useEffect(() => {
    if (welcome.subscriptionDetails.subscriptionRenewalDate <= zoneDate)
      setSubscriptionExpired(true);

    if (
      welcome.subscriptionDetails.reportsRun >=
      welcome.subscriptionDetails.reportsAllowed
    )
      setReportLimitMet(true);
  }, [welcome]);

  const validateVin = (vin) => {
    const re = new RegExp("^[A-Z\\d]{12,17}$");
    return vin.match(re);
  };

  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  const { userDetails } = useSelector((state) => {
    return {
      userDetails: state.login.userDetails,
    };
  });

  const manageUserNavigate = async () => {
    props.history.push("/manageuser");
  };

  const getReport = () => {
    if (validateVin(details.vin)) {
      axios
        .get(
          `${dealerPortalEndPoints.sessionInfo}${userDetails.userKey}`,
          UseAuthorization()
        )
        .then((response) => {
          if (
            response.data.sessionKey === localStorage.getItem("session_key")
          ) {
            dispatch(
              getVehicleHistoryReport(
                () => {
                  setDetails({ vin: "" });
                },
                localStorageData?.dealerNumber,
                details.vin,
                userDetails.userKey
              )
            );
          } else if (
            window.confirm(
              "Username already logged in. Click OK to exit other session and log in here."
            )
          ) {
            axios
              .delete(
                `${dealerPortalEndPoints.sessionInfo}${userDetails.userKey}`,
                UseAuthorization()
              )
              .then((_response) => {
                dispatch(logoutUser());
              });
          }
        });
    }
    document.getElementById("vinField").focus();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setDetails({ ...details, [name]: value.toUpperCase() });
  };

  const renderOrgTitle = () => {
    return userDetails?.userDealerMappings.find(
      (dealer) => dealer.orgKey === localStorageData?.dealerNumber.toUpperCase()
    )?.orgDisplay;
  };

  const renderReportLimitMessage = () => {
    if (reportLimitMet) {
      return (
        <FormHelperText className={globalClasses.subscriptionMessage}>
          {UserMessages.REPORT_LIMIT_MET}
        </FormHelperText>
      );
    }
  };

  const renderSubExpired = () => {
    if (subscriptionExpired) {
      return (
        <FormHelperText className={globalClasses.subscriptionMessage}>
          {UserMessages.SUB_EXPIRED}
        </FormHelperText>
      );
    }
  };

  return (
    <div>
      <PageHeader userDetails={userDetails} showUser={true} page={"welcome"} />
      <CssBaseline />
      {
        <Backdrop
          className={globalClasses.backdrop}
          open={!userDetails?.userDealerMappings}
        >
          <CircularProgress
            className={clsx(globalClasses.spinnerSecondary)}
            size={75}
          />
        </Backdrop>
      }
      {!userDetails?.userDealerMappings ? null : (
        <Container maxWidth="md" className={globalClasses.container}>
          <div className={globalClasses.appBarSpacer} />
          <Grid container spacing={2}>
            <Grid item xs={12} className={globalClasses.titleGrid}>
              <Typography className={globalClasses.pageTitleText}>
                Welcome - {renderOrgTitle()}
              </Typography>
              <Divider className={globalClasses.headerDividerStyle} />
            </Grid>
            <Grid item xs={12}>
              <Paper className={globalClasses.paper}>
                <Grid item xs={12}>
                  <Typography className={globalClasses.pageSectionTitle}>
                    Report
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <br />
                <Grid item xs={4}>
                  <TextField
                    name="vin"
                    label="VIN"
                    onChange={handleChange}
                    className={clsx(
                      globalClasses.textField,
                      globalClasses.normalFormField
                    )}
                    value={details.vin}
                    inputProps={{ maxLength: 17 }}
                    variant="outlined"
                    autoFocus
                    onKeyUp={(event) => {
                      event.keyCode == 13 && getReport();
                    }}
                    fullWidth
                    id="vinField"
                  />
                  {welcome.error && (
                    <FormHelperText
                      className={globalClasses.formHelperTextError}
                    >
                      {`*${welcome.errormsg}`}
                    </FormHelperText>
                  )}
                </Grid>
                <br />
                <Grid item xs={12}>
                  <Typography className={globalClasses.pageSectionTitle}>
                    Subscription Information
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider light />
                </Grid>
                <br />
                <Grid item xs={12}>
                  <SubscriptionInformation
                    tableData={welcome.subscriptionDetails}
                    subscriptionExpired={subscriptionExpired}
                    reportLimitMet={reportLimitMet}
                  />
                  {renderSubExpired()}
                  {renderReportLimitMessage()}
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={3}>
              {userDetails?.isSHLAdmin && (
                <Button
                  className={globalClasses.normalButton}
                  onClick={manageUserNavigate}
                >
                  Manage Users
                </Button>
              )}
            </Grid>
            <Grid item xs={5} />
            <Grid item xs={4}>
              {!welcome.GenerateReportLoading ? (
                <Button
                  className={globalClasses.confirmationButton}
                  disabled={
                    !validateVin(details.vin) ||
                    welcome.GenerateReportLoading ||
                    reportLimitMet ||
                    subscriptionExpired
                  }
                  color="primary"
                  onClick={() => getReport()}
                >
                  Generate Report
                </Button>
              ) : (
                <Button className={globalClasses.confirmationButton}>
                  <CircularProgress
                    size={24}
                    className={globalClasses.buttonProgress}
                  />
                  Generating Report...
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      )}
    </div>
  );
};

export default Welcome;
