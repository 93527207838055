import { combineReducers } from "redux";
import loginReducer from "./loginReducer";
import manageUser from "./manageUserReducer";
import welcomeReducer from "./welcomeReducer";
import toastReducer from "./toastReducer";
import { reducer as oidcreducer } from "redux-oidc";

const root = combineReducers({
  login: loginReducer,
  oidc: oidcreducer,
  managedUsers: manageUser,
  toastsReducer: toastReducer,
  welcome: welcomeReducer,
});
export default root;
