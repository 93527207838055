import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import root from "../reducers/rootReducer";
import { composeWithDevTools } from "redux-devtools-extension";

const configureStore = () => {
  return createStore(root, composeWithDevTools(applyMiddleware(thunk)));
};

export default configureStore;
