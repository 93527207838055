import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Divider,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { hideToastMessage } from "../../action/toastAction";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const Toast = (props) => {
  const globalClasses = globalStyle();
  const dispatch = useDispatch();
  const toasts = useSelector((state) => {
    return state.toastsReducer;
  });
  const [open, setOpen] = React.useState<boolean>(toasts.isOpen);

  const handleClose = (event, reason) => {
    setOpen(!open);
    if (reason === "clickaway") {
      return;
    }
    dispatch(hideToastMessage());
  };

  return (
    <div>
      <Dialog
        open={toasts.isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className={globalClasses.warningDialogTitle}>
          {toasts.type}
          <Divider className={globalClasses.sectionDivider} light />
        </DialogTitle>
        <DialogContent>
          <DialogContentText className={globalClasses.text}>
            {toasts.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            className={globalClasses.confirmationButton}
          >
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Toast;
