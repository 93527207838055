import {
  GET_REPORT_ACTION_FAILED,
  GET_REPORT_ACTION_SUCCESS,
  GENERATE_REPORT_IN_PROGRESS,
  GET_SUBSCRIPTION_DETAILS_SUCCESS,
} from "../actionTypes/welcomeActionTypes";

const INITIAL_STATE = {
  error: false,
  errormsg: "",
  GenerateReportLoading: false,
  subscriptionDetails: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GENERATE_REPORT_IN_PROGRESS:
      return { ...state, GenerateReportLoading: true };
    case GET_REPORT_ACTION_FAILED:
      return {
        ...state,
        error: true,
        errormsg: action.payload,
        GenerateReportLoading: false,
      };
    case GET_REPORT_ACTION_SUCCESS:
      return {
        ...state,
        error: false,
        errormsg: "",
        GenerateReportLoading: false,
      };
    case GET_SUBSCRIPTION_DETAILS_SUCCESS:
      return {
        ...state,
        subscriptionDetails: action.payload,
      };
    default:
      return state;
  }
};
