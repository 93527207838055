import React, { useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import {
  Grid,
  Box,
  Typography,
  Divider,
  CssBaseline,
  Container,
  IconButton,
  Backdrop,
  CircularProgress,
  Button,
} from "@material-ui/core";
import { hddsStyles, HtmlTooltip } from "../../core/styles/styles";
import PageHeader from "../pageheader/PageHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  updateSelectedUser,
  getOrganization,
  deleteUserDetails,
} from "../../action/manageUserAction";
import { showToastMessage } from "../../action/toastAction";
import { getUserDetails } from "../../action/welcomeAction";
import {
  Grid as KendoGrid,
  GridColumn as Column,
} from "@progress/kendo-react-grid";
import {
  CompositeFilterDescriptor,
  SortDescriptor,
  orderBy,
  filterBy,
} from "@progress/kendo-data-query";
import clsx from "clsx";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import { ConfirmDialog } from "../dialogs/confirmDialog";
import { UserMessages } from "../../enums/usermessages";

const globalStyle = hddsStyles;

export default function ManageUser(props) {
  const globalClasses = globalStyle();
  const [deleteUser, setDeleteUser] = React.useState(null);
  const [deleteUserDialog, setDeleteUserDialog] = React.useState(false);
  const [gridData, setGridData] = React.useState([]);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>({
    logic: "and",
    filters: [],
  });
  const [sorter, setSorter] = React.useState<SortDescriptor[]>([
    { field: "employee", dir: "asc" },
  ]);

  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  const dispatch = useDispatch();

  const { users, subscriptions } = useSelector((state) => state.managedUsers);

  const { userDetails } = useSelector((state) => {
    return {
      userDetails: state.login.userDetails,
    };
  });

  useEffect(() => {
    dispatch(getUserDetails());
    setGridStringValues();
  }, []);

  useEffect(() => {
    if (users) {
      setGridData(orderBy(filterBy(users, filter), sorter));
    }
  }, [filter, sorter]);

  useEffect(() => {
    if (!users) {
      dispatch(getOrganization(localStorageData.dealerNumber));
    }
    setGridStringValues();
  }, [users]);

  const setGridStringValues = () => {
    users?.forEach((user) => {
      user.isAdmin = renderAdminValue(user.admin);
    });
    setGridData(users);
  };

  const handleEditUser = (user) => {
    dispatch(
      updateSelectedUser(user, () => {
        props.history.push(`/edituser/${user.username}`);
      })
    );
  };

  const handleAddUserClick = () => {
    dispatch(
      updateSelectedUser(null, () => {
        props.history.push(`/adduser`);
      })
    );
  };

  const handleDeleteUser = (user) => {
    const adminCount = users.filter((x) => x.admin).length;
    if (user?.admin && adminCount === 1) {
      dispatch(
        showToastMessage({
          message: UserMessages.ADMIN_REQ,
          status: "warning",
        })
      );
      return;
    }
    setDeleteUser(user);
    setDeleteUserDialog(true);
  };

  const completeDeleteUser = () => {
    dispatch(deleteUserDetails(deleteUser));
    setDeleteUser(null);
  };

  const renderAdminValue = (isAdmin) => {
    return isAdmin ? "Y" : null;
  };

  return (
    <div>
      {
        <Backdrop
          className={globalClasses.backdrop}
          open={!subscriptions && !users}
        >
          <CircularProgress
            className={clsx(globalClasses.spinnerSecondary)}
            size={75}
          />
        </Backdrop>
      }
      <PageHeader
        userDetails={userDetails}
        showUser={true}
        page={"manage_user"}
      />
      <CssBaseline />
      <ConfirmDialog
        open={deleteUserDialog}
        title={"Delete User"}
        message={`${UserMessages.DELETE_USER} ${deleteUser?.username}?`}
        setOpen={setDeleteUserDialog}
        onConfirm={completeDeleteUser}
      />
      <Container maxWidth="lg" className={globalClasses.containerLarge}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Manage Users
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          {!subscriptions && !users ? null : (
            <>
              <Grid item xs={12}>
                <Paper className={globalClasses.pagePaper}>
                  <Grid container item xs={12} justify="flex-start">
                    <Typography className={globalClasses.pageSectionTitle}>
                      Subscriptions
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {!subscriptions ? null : (
                      <KendoGrid
                        data={subscriptions}
                        className={clsx("condensedKendoGrid")}
                      >
                        <Column field="store" title="Store" />
                        <Column field="subscription" title="Subscription" />
                        <Column
                          field="subscriptionUsers"
                          title="Max # of Users Allowed"
                        />
                        <Column
                          field="noOfUsersAssigned"
                          title="# of Users Assigned"
                        />
                      </KendoGrid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper
                  className={globalClasses.pagePaper}
                  style={{ marginBottom: "0" }}
                >
                  <Grid item xs={12}>
                    <Typography className={globalClasses.pageSectionTitle}>
                      Users
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider light />
                  </Grid>
                  <br />
                  <Grid item xs={12}>
                    {!users ? null : (
                      <KendoGrid
                        sortable
                        filterable
                        reorderable
                        data={gridData}
                        filter={filter}
                        onFilterChange={(e) => {
                          setFilter(e.filter);
                        }}
                        sort={sorter}
                        onSortChange={(e) => {
                          setSorter(e.sort);
                        }}
                        className={clsx(
                          "condensedKendoGrid",
                          globalClasses.kGridWithEdit,
                          globalClasses.kGridWithDelete
                        )}
                      >
                        <Column
                          width="30px"
                          orderIndex={0}
                          filterable={false}
                          sortable={false}
                          resizable={false}
                          cell={(GridCellProps) => {
                            return (
                              <td>
                                <HtmlTooltip title={<>Edit User</>}>
                                  <IconButton
                                    onClick={() =>
                                      handleEditUser(GridCellProps.dataItem)
                                    }
                                  >
                                    <EditOutlinedIcon />
                                  </IconButton>
                                </HtmlTooltip>
                              </td>
                            );
                          }}
                        />
                        <Column field="employee" title="Employee" />
                        <Column
                          field="username"
                          title="Username"
                          width="300px"
                        />
                        <Column
                          field="isAdmin"
                          title="Admin"
                          width="125px"
                          cell={(GridCellProps) => {
                            return (
                              <td style={{ textAlign: "center" }}>
                                {GridCellProps.dataItem.isAdmin}
                              </td>
                            );
                          }}
                        />
                        <Column field="defaultStore" title="Default Store" />
                        <Column
                          width="30px"
                          orderIndex={0}
                          filterable={false}
                          sortable={false}
                          resizable={false}
                          cell={(GridCellProps) => {
                            return (
                              <td>
                                <HtmlTooltip title={<>Delete User</>}>
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteUser(GridCellProps.dataItem)
                                    }
                                  >
                                    <DeleteOutlinedIcon />
                                  </IconButton>
                                </HtmlTooltip>
                              </td>
                            );
                          }}
                        />
                      </KendoGrid>
                    )}
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
          <Grid item xs={9} />
          <Grid item xs={3}>
            {!users && !subscriptions ? null : (
              <Box style={{ float: "right" }}>
                <Button
                  className={globalClasses.confirmationButton}
                  onClick={handleAddUserClick}
                >
                  Add User
                </Button>
                <Button
                  className={globalClasses.cancelButton}
                  onClick={() => {
                    props.history.push("/welcome");
                  }}
                >
                  Exit
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
