import { makeStyles } from "@material-ui/core";

export const LoginStyles = makeStyles((theme) => ({
  panelBody: {
    background: "transparent",
    padding: "24px 15px 14px 0px",
    width: "550px",
    justifyContent: "center",
    "@media (max-width: 600px)": {
      marginLeft: 0,
    },
  },
  loginFooter: {
    color: "black",
    textAlign: "center",
    marginTop: "10px",
    "@media (max-width: 600px)": {
      marginLeft: 0,
    },
  },
  fieldContainer: {
    display: "inline-flex",
    margin: "5px 0 5px -5px",
    width: "100%",
  },

  errorMessage: {
    color: "red",
    marginLeft: "40px",
  },
}));
