//will hold the code related to token

import { createUserManager } from "redux-oidc";
import { identityServerEndPoints } from "../Constants/apiConstants";

const userManagerConfig = {
  authority: identityServerEndPoints.login,
  client_id: "external-dealer-portal",
  redirect_uri: identityServerEndPoints.redirect,
  response_type: "id_token token",
  scope: "openid idds-api-administration",
  post_logout_redirect_uri: identityServerEndPoints.postLogoutRedirect,
};

const userManager = createUserManager(userManagerConfig);

export default userManager;
