import * as React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

export interface IDialog {
  open: boolean;
  title: string;
  message: any;
  setOpen: (result: boolean) => void;
  onConfirm: () => void;
}

const globalStyle = hddsStyles;

export function ConfirmDialog(props: IDialog): JSX.Element {
  const globalClasses = globalStyle();
  const { open, title, message, setOpen, onConfirm } = props;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="xs">
      <DialogTitle className={globalClasses.warningDialogTitle}>
        {title}
        <Divider className={globalClasses.sectionDivider} light />
      </DialogTitle>
      <DialogContent>{message}</DialogContent>
      <br />
      <DialogActions>
        <div style={{ float: "right" }}>
          <Button
            className={globalClasses.confirmationButton}
            onClick={() => {
              setOpen(false);
              onConfirm();
            }}
            autoFocus
          >
            Yes
          </Button>
          <Button
            className={globalClasses.cancelButton}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
