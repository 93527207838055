import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const AddingUsers = (_props) => {
  const globalClasses = globalStyle();

  return (
    <div>
      <PageHeader page={"add_user"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Adding and Editing Users
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <p>
                  There are two ways to manage specific users. First, by
                  selecting 
                  <img
                    height="25"
                    src="assets/images/add user button.png"
                    width="70"
                  />
                   on the Manage User screen or, second, selecting the 
                  <img src="assets/images/edit icon.png" />
                   icon next to the employee name on the Manage User screen.
                </p>
                <p>
                  From this screen, you add a new user or edit an existing user
                  by completing the form.
                </p>
                <p>
                  <img
                    height="582"
                    src="assets/images/add_user.png"
                    style={{ border: "1px solid black" }}
                    width="800"
                  />
                </p>
                <table
                  style={{
                    width: "100%",
                    captionSide: "bottom",
                    borderCollapse: "collapse",
                  }}
                >
                  <caption style={{ fontStyle: "italic", textAlign: "left" }}>
                    *Required field
                  </caption>
                  <colgroup>
                    <col style={{ width: "167px" }} />
                    <col style={{ width: "650px" }} />
                  </colgroup>
                  <tbody>
                    <tr style={{ backgroundColor: "#556689", color: "#fff" }}>
                      <td style={{ padding: "5px 0px 5px 10px" }}>Field</td>
                      <td>Description</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        First Name*
                      </td>
                      <td></td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Last Name*
                      </td>
                      <td></td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Password*
                      </td>
                      <td>
                        <p>
                          User passwords must be at least 7 characters and
                          contain at least one uppercase letter, one lowercase
                          letter, one number, and one special character (!, *,
                          @, #, $, %, ^, &amp;, +, =).
                        </p>
                        <p>
                          Retype the password in the Confirm Password box to
                          continue.
                        </p>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Login Name*
                        <br />
                        (Username)
                      </td>
                      <td>
                        <p>
                          Username used to log into SHL. Must be between 4 and
                          30 characters, letters and numbers only.
                        </p>
                        <p
                          style={{
                            backgroundColor: "#eef6fc",
                            color: "#1d72aa",
                            padding: "5px",
                          }}
                        >
                          The username cannot be edited after the initial record
                          is created. Ensure the name is correct when adding a
                          new user.
                        </p>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Admin
                      </td>
                      <td>
                        <p>
                          <strong>Admin box</strong>
                        </p>
                        <p>
                          Select this option to give the user admin privileges
                          (add users and change passwords).  
                        </p>
                        <p>
                          It is recommended that you have at least two admins in
                          your organization to assist users with passwords.
                        </p>
                        <p>
                          <img
                            src="assets/images/add_user_admin_box.png"
                            style={{ border: "1px solid black" }}
                          />
                        </p>
                        <p>
                          <strong>Locked box</strong>
                        </p>
                        <p>
                          If a user enters their password incorrectly too many
                          times, their account will be locked. Admin&#39;s can
                          clear this box, allowing the user to try again (only
                          visible on the edit user page).
                        </p>
                        <p>
                          <img
                            src="assets/images/locked.png"
                            style={{ border: "1px solid black" }}
                          />
                        </p>
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Store Access Grid
                      </td>
                      <td>
                        <p>
                          A listing of all valid subscriptions available to the
                          organization. Select the box next to the dealership
                          name to grant the user access that store&#39;s
                          subscription. 
                        </p>
                        <ul>
                          <li>
                            <strong>Subscription bo</strong>
                            <strong>x</strong>: select to give the user access
                            to this store&#39;s subscription.
                          </li>
                          <li>
                            <strong>Store Name</strong>: the dealership in the
                            organization with a valid subscription.
                          </li>
                          <li>
                            <strong>Default box</strong>: select to mark this
                            store as the user&#39;s default store.
                          </li>
                          <li>
                            <strong>Subscription</strong>: the subscription name
                            (type) that the dealership has enrolled in.
                          </li>
                        </ul>
                        <p
                          style={{
                            backgroundColor: "#eef6fc",
                            color: "#1d72aa",
                            padding: "5px",
                          }}
                        >
                          The row with each dealership will be disabled when the
                          maximum number of users has been reached for that
                          store&#39;s subscription level. 
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <p>
                  <div
                    style={{
                      backgroundColor: "#eef6fc",
                      borderRadius: "4px",
                      padding: "1.25em 1.5em",
                      borderColor: "#3298dc",
                      borderStyle: "solid",
                      borderWidth: "0 0 0 4px",
                      color: "#1d72aa",
                    }}
                  >
                    You can add users and not assign them a store. However, they
                    will not be able to login to the SHL site.
                  </div>
                </p>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default AddingUsers;
