import {
  FETCH_USER_SUCCESS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  SELECT_USER,
  ADD_USER_SUCCESS,
  ADD_USER_FAILED,
  FETCH_ORGANIZATION_SUCCESS,
  FETCH_USER,
  FETCH_USER_FAILED,
  DELETE_USER_FAILED,
  DELETE_USER_SUCCESS,
  DELETE_USER,
} from "./../actionTypes/manageUserActionTypes";
import axios from "axios";
import { dealerPortalEndPoints } from "../Constants/apiConstants";
import {
  UPDATE_USER_SUCCESS,
  UPDATE_SUBSCRIPTION_SUCCESS,
} from "../actionTypes/manageUserActionTypes";
import { showToastMessage } from "./toastAction";
import { UseAuthorization } from "../utils/UseAuthorization";
import { logoutUser } from "./loginAction";
import { UserMessages } from "../enums/usermessages";

export const getOrganization = (dealerNumber) => {
  return async (dispatch) => {
    dispatch({ type: FETCH_USER });
    await axios
      .get(
        `${dealerPortalEndPoints.getOrganization}${dealerNumber}?byDealer=true`,
        UseAuthorization()
      )
      .then(async (response) => {
        const organization = {
          name: response.data?.orgName,
        };
        const userList = ((response.data.users as any) || []).map((user) => {
          return parseUserDetails(user);
        });
        const subscriptions = ((response.data.dealers as any) || []).map(
          (sub) => {
            return {
              store: sub.dealerName,
              subscription: sub.subscriptionDescription,
              subscriptionUsers: sub.subscriptionUsersTotal,
              noOfUsersAssigned: sub.subscriptionUsersCurrentlyAssigned,
            };
          }
        );
        await dispatch(getOrgSuccess(organization));
        await dispatch(getSubscriptionsSuccess(subscriptions));
        await dispatch(getUserSuccess(userList));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
        dispatch({ type: FETCH_USER_FAILED });
      });
  };
};

export const getUsers = (userkey, dealerNumber, organization) => {
  return async (dispatch) => {
    await axios
      .get(
        `${dealerPortalEndPoints.getUserDetails}${userkey}@${organization}?getAllInOrg=true`,
        UseAuthorization()
      )
      .then(async (response) => {
        const userList = ((response.data as any) || []).map((user) => {
          return parseUserDetails(user);
        });
        await dispatch(getSubscriptions(dealerNumber));
        await dispatch(getUserSuccess(userList));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
};

const parseUserDetails = (user) => {
  return {
    userKey: user.userKey,
    employee: user.userDisplay,
    userDisplay: user.userDisplay,
    username: user.userName,
    admin: user.isSHLAdmin,
    defaultStore: user.defaultStore,
    userDealerMappings: user.userDealerMappings,
    userLocked: user.userIsLocked,
  };
};

export const getUser = (userkey, organization) => {
  return async (dispatch) => {
    return await axios
      .get(
        `${dealerPortalEndPoints.getUserDetails}${userkey}@${organization}`,
        UseAuthorization()
      )
      .then(async (user) => {
        if (user.data && user.data.length > 0) {
          user.data[0].username = user.data[0].userName;
          dispatch(updateSelectedUser(user.data[0], () => {}));
          return user.data;
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
};

export const getSubscriptions = (dealerNumber) => {
  return async (dispatch) => {
    await axios
      .get(
        `${dealerPortalEndPoints.getSubscriptions}${dealerNumber}?byDealer=true`,
        UseAuthorization()
      )
      .then((response) => {
        const subscriptions = ((response.data.dealers as any) || []).map(
          (sub) => {
            return {
              store: sub.dealerName,
              subscription: sub.subscriptionDescription,
              subscriptionUsers: sub.subscriptionUsersTotal,
              noOfUsersAssigned: sub.subscriptionUsersCurrentlyAssigned,
            };
          }
        );
        dispatch(getSubscriptionsSuccess(subscriptions));
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          dispatch(logoutUser());
        }
      });
  };
};

export const getSubscriptionsSuccess = (subscriptions) => {
  return (dispatch) => {
    dispatch({ type: FETCH_SUBSCRIPTIONS_SUCCESS, payload: subscriptions });
  };
};

export const getOrgSuccess = (organization) => {
  return (dispatch) => {
    dispatch({ type: FETCH_ORGANIZATION_SUCCESS, payload: organization });
  };
};

export const getUserSuccess = (users) => {
  return (dispatch) => {
    dispatch({ type: FETCH_USER_SUCCESS, payload: users });
  };
};

export const addUser = (userDetails, resetFormCallBack, isUpdateUser) => {
  if (!isUpdateUser) {
    return async (dispatch) => {
      const userExist = await axios
        .get(
          `${dealerPortalEndPoints.getUserDetails}${userDetails.userKey}`,
          UseAuthorization()
        )
        .then((response) => {
          return response.data && response.data.length > 0 ? true : false;
        });
      if (userExist) {
        dispatch(
          showToastMessage({
            message: UserMessages.USER_EXISTS,
            status: "Warning",
          })
        );
        dispatch({ type: ADD_USER_FAILED });
        return;
      }

      await axios
        .post(
          dealerPortalEndPoints.addUserDetails,
          userDetails,
          UseAuthorization()
        )
        .then(async (response) => {
          resetFormCallBack();
          await dispatch(
            showToastMessage({
              message: UserMessages.CREATE_SUCCESS,
              status: "Success",
            })
          );
          await dispatch(addUserSuccess());
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logoutUser());
          }
          dispatch(
            showToastMessage({
              message: error?.response?.data
                ? error.response.data
                : UserMessages.CREATE_FAIL,
              status: "Error",
            })
          );
          dispatch({ type: ADD_USER_FAILED });
        });
    };
  } else {
    return async (dispatch) => {
      await axios
        .put(
          dealerPortalEndPoints.addUserDetails,
          userDetails,
          UseAuthorization()
        )
        .then(async (response) => {
          resetFormCallBack();
          await dispatch(
            showToastMessage({
              message: UserMessages.UPDATE_SUCCESS,
              status: "Success",
            })
          );
          await dispatch(addUserSuccess());
        })
        .catch((error) => {
          if (error?.response?.status === 401) {
            dispatch(logoutUser());
          }
          const message =
            error?.response?.status === 400 && error.response.data
              ? error.response.data
              : UserMessages.UPDATE_FAIL;
          dispatch({ type: ADD_USER_FAILED });
          dispatch(
            showToastMessage({
              message: message,
              status: "Error",
            })
          );
        });
    };
  }
};

export const addUserSuccess = () => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_SUCCESS });
  };
};

export const addUserFailed = () => {
  return (dispatch) => {
    dispatch({ type: ADD_USER_FAILED });
  };
};

export const updateUser = (userDetails) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_USER_SUCCESS, payload: userDetails });
  };
};

export const updateSubscription = (subscriptions) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SUBSCRIPTION_SUCCESS, payload: subscriptions });
  };
};

export const updateSelectedUser = (user, callback) => {
  return (dispatch) => {
    dispatch({ type: SELECT_USER, payload: user });
    if (callback && typeof callback == "function") callback();
  };
};

export const deleteUserDetails = ({ userKey }) => {
  if (!userKey) {
    return;
  }
  return async (dispatch) => {
    dispatch({ type: DELETE_USER });
    await axios
      .delete(
        `${dealerPortalEndPoints.deleteUserDetails}${userKey}`,
        UseAuthorization()
      )
      .then(async (response) => {
        dispatch(
          await showToastMessage({
            message: UserMessages.DELETE_SUCCESS,
            status: "Success",
          })
        );
        dispatch({ type: DELETE_USER_SUCCESS });
      })
      .catch((error) => {
        const message =
          error?.response?.status === 400 && error.response.data
            ? error.response.data
            : UserMessages.DELETE_FAIL;
        dispatch(
          showToastMessage({
            message: message,
            status: "Error",
          })
        );
        dispatch({ type: DELETE_USER_FAILED });
      });
  };
};
