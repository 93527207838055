import React from "react";
import { Link, Typography } from "@material-ui/core";

const PageFooter = (props) => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`Copyright © ${new Date().getFullYear()} - `}
      <Link color="inherit" href="https://hdds.com/">
        TALON Powersports Solutions
      </Link>
    </Typography>
  );
};

export default PageFooter;
