import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const CPOInterpretation = (_props) => {
  const globalClasses = globalStyle();
  return (
    <div>
      <PageHeader page={"cpo_inter"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              CPO Interpretation
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <div>
                <p>
                  The Service History Report can be used help the service
                  department determine bike eligibility in the HD-Certified™
                  program. The SHL report offers insight into required bike
                  maintenance and potential mechanical problems and help the
                  dealership complete the checklist provided by the motor
                  company.
                </p>
                <p>
                  Below are samples from Service History Reports that help
                  illustrate how the report can help with vehicle eligibility.  
                </p>
                <h2>Service Intervals</h2>
                <figure>
                  <img
                    src="assets/images/image.png"
                    style={{ border: "1px solid black" }}
                  />
                  <figcaption>H-D Certified™ checklist example.</figcaption>
                </figure>
                <p>
                  The SHL report will contain the mileage and service performed
                  (if by a authorized and participating H-D dealer) on the bike.
                  Based on the mileage for each service entry, you can determine
                  if regular service was performed at the recommended intervals
                  (5K, 10K, 15K, etc.).
                </p>
                <p>
                  For example, the following service record shows that the bike
                  received its 10K service.
                </p>
                <p>
                  <img src="assets/images/image1.png" />
                </p>
                <p>
                  Conversely, this bike does not have any record of a 10K
                  service, and would not qualify (unless the vehicle mileage is
                  within the recommended time frame allowing the dealership to
                  perform the service before certifying).
                </p>
                <p>
                  <img src="assets/images/image2.png" />
                </p>
                <h2>Maintenance Issues</h2>
                <p>
                  The example vehicle below shows the proper service interval,
                  but also extensive repairs. The vehicle would need to be
                  carefully examined to ensure that the vehicle meets the H-D
                  Certified standards.
                </p>
                <p>
                  <img src="assets/images/damage example.png" />
                </p>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CPOInterpretation;
