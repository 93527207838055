import React from "react";
import AddUser from "./AddUser";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getUser } from "../../action/manageUserAction";
import { getUserDetails } from "../../action/welcomeAction";

const EditUser = (props) => {
  const userId = props.match.params.id;
  const { selectedUser } = useSelector((state) => state.managedUsers);
  const { userDetails } = useSelector((state) => {
    return {
      userDetails: state.login.userDetails,
    };
  });
  const [enableEdit, setEnableEdit] = useState(false);
  const localStorageData = JSON.parse(localStorage.getItem("dp_userDetails"));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedUser) {
      Promise.all([
        dispatch(getUserDetails()),
        dispatch(getUser(userId, localStorageData.organization)),
      ]).then((response) => {
        if (
          response[0] &&
          response[0].length > 0 &&
          response[1] &&
          response[1].length > 0
        ) {
          setEnableEdit(true);
        }
      });
    } else {
      setEnableEdit(true);
    }
  }, []);

  return enableEdit && <AddUser {...props} isEdit={true}></AddUser>;
};

export default EditUser;
