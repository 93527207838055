import { makeStyles } from "@material-ui/core";

export const AddUserStyles = makeStyles((theme) => ({
  customField: {
    margin: "8px 8px",
    width: "96%",
  },
  listContainer: {
    backgroundColor: "lightgrey",
    borderRadius: "4px",
    fontSize: "1em",
    float: "left",
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    width: "96%",
    marginLeft: "8px",
  },
}));
