import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const GeneratingReport = (_props) => {
  const globalClasses = globalStyle();
  return (
    <div>
      <PageHeader page={"gen_report"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Generating a Report
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <p>
                  To Generate a Service History-Link report, follow these steps:
                </p>
                <img src="assets/images/generate_report_nonadmin.png" />
                <ol>
                  <li>Enter or copy and paste a valid VIN.</li>
                  <li>
                    Select <b>Generate Report</b> (only enabled if the VIN
                    entered is 12-17 characters).
                  </li>
                  <li>A PDF of the report will open in your browser.</li>
                </ol>
                Note:
                <ul>
                  <li>VIN's must be between 12-17 alpha-numeric characters.</li>
                  <li>
                    If the number is not found, "VIN not available at this time"
                    will display.
                  </li>
                  <li>
                    If there was an error processing the request, "Communication
                    Failed" will display.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <Typography className={globalClasses.pageSectionTitle}>
                  Subscription Information
                </Typography>
                <p>
                  Before running a report, you can easily view your current
                  subscription details for the store that you are logged in
                  under.
                </p>
                <img src="assets/images/generate_report_subscription_information.png" />
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default GeneratingReport;
