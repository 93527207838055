import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { hddsStyles, HtmlTooltip } from "../../core/styles/styles";
import {
  Typography,
  AppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
} from "@material-ui/core";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import { IUserDetails } from "../../models/IUserDetails";
import { logoutUser } from "../../action/loginAction";

const globalStyle = hddsStyles;

type PageHeaderProps = {
  userDetails?: IUserDetails;
  showUser: boolean;
  page: string;
};

const PageHeader = (props: PageHeaderProps) => {
  const { userDetails, showUser } = props;
  const globalClasses = globalStyle();
  const history = useHistory();
  const dispatch = useDispatch();
  const id_token = localStorage.getItem("dp_user_id_token");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const faqLink =
    "https://www.hdds.com/media/documents/service%20history-link%20portal/Service%20History-Link%20Portal%20Help.pdf";

  const helperPageNavigate = async () => {
    if (id_token) {
      switch (props.page) {
        case "welcome":
          history.push("/help/Generating_a_Report");
          break;
        case "manage_user":
          history.push("/help/Managing_Your_Subscription");
          break;
        case "add_user":
        case "edit_user":
          history.push("/help/Adding_Users");
          break;
        default:
          history.push("/help");
          break;
      }
    } else {
      const newwindow = window.open(faqLink, "_blank", "noopener, noreferrer");
      if (newwindow) {
        newwindow.opener = null;
      }
    }
  };

  const logOut = () => {
    dispatch(logoutUser());
  };

  const handleImageClick = () => {
    if (showUser) history.push("/welcome");
    return;
  };

  return (
    <div>
      <AppBar position="absolute">
        <Toolbar className={globalClasses.toolbar}>
          <img
            className={globalClasses.logo}
            src={"/assets/images/Harley-DavidsonNBG.png"}
            onClick={handleImageClick}
          />
          <Typography className={globalClasses.title}>
            Service History-Link
          </Typography>
          {showUser && (
            <div>
              <p style={{ float: "left" }}>{userDetails?.userName}</p>
              <IconButton
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircleOutlinedIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={open}
                onClose={handleClose}
              >
                <MenuItem disabled>{userDetails?.userName}</MenuItem>
                <MenuItem onClick={logOut}>Log Out</MenuItem>
              </Menu>
            </div>
          )}
          <HtmlTooltip title={<>{"help"}</>}>
            <IconButton
              edge="end"
              color="inherit"
              aria-label="open drawer"
              onClick={helperPageNavigate}
            >
              <HelpOutlineIcon />
            </IconButton>
          </HtmlTooltip>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export default PageHeader;
