import {
  FETCH_USER,
  ADD_USER,
  ADD_USER_FAILED,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILED,
  DELETE_USER,
} from "./../actionTypes/manageUserActionTypes";
import {
  FETCH_USER_FAILED,
  FETCH_USER_SUCCESS,
  FETCH_SUBSCRIPTIONS_SUCCESS,
  SELECT_USER,
  ADD_USER_SUCCESS,
  FETCH_ORGANIZATION_SUCCESS,
} from "../actionTypes/manageUserActionTypes";

const INITIAL_STATE = {
  users: null,
  selectedUser: null,
  error: null,
  subscriptions: null,
  organization: null,
  user_loading: false,
  add_user_in_process: false,
  delete_user_in_process: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SELECT_USER:
      return { ...state, selectedUser: action.payload };
    case FETCH_USER:
      return { ...state, user_loading: true };
    case FETCH_USER_SUCCESS:
      return { ...state, users: action.payload, user_loading: false };
    case FETCH_USER_FAILED:
      return { ...state, error: action.payload, user_loading: false };
    case FETCH_SUBSCRIPTIONS_SUCCESS:
      return {
        ...state,
        subscriptions: action.payload,
      };
    case ADD_USER:
      return { ...state, add_user_in_process: true };
    case ADD_USER_SUCCESS:
      return {
        ...state,
        subscriptions: null,
        users: null,
        add_user_in_process: false,
      };
    case ADD_USER_FAILED:
      return {
        ...state,
        add_user_in_process: false,
      };
    case DELETE_USER:
      return {
        ...state,
        delete_user_in_process: true,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        users: null,
        delete_user_in_process: false,
      };
    case DELETE_USER_FAILED:
      return {
        ...state,
        delete_user_in_process: false,
      };
    case FETCH_ORGANIZATION_SUCCESS:
      return { ...state, organization: action.payload };
    default:
      return state;
  }
};
