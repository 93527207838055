export enum UserMessages {
  DEFUALT_STORE = "Please select one default store",
  ADMIN_REQ = "At least one admin user is required. You must set another user as admin first.",
  DEALER_REQ = "Select at least one dealer",
  DELETE_USER = "Are you sure you want to delete the selected user",
  UPDATE_FAIL = "Failed to Update User",
  UPDATE_SUCCESS = "User Updated Successfully",
  CREATE_FAIL = "Failed to Create User",
  CREATE_SUCCESS = "User Created Successfully",
  USER_EXISTS = "User Already Exists",
  DELETE_FAIL = "Failed to Delete User",
  DELETE_SUCCESS = "User Deleted Successfully",
  REPORT_LIMIT_MET = "Your dealership has reached your allowed report limit for your subscription. Please contact HDDS for resolution. 800-428-5090 (Option 1)",
  SUB_EXPIRED = "Your dealership's subscription has expired. Please contact HDDS for resolution. 800-428-5090 (Option 1)",
}
