import * as types from "../actionTypes/toastActionTypes";

export const showToastMessage = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SHOW_TOAST_MESSAGE, payload: data });
  };
};

export const hideToastMessage = () => {
  return (dispatch) => {
    dispatch({ type: types.HIDE_TOAST_MESSAGE });
  };
};
