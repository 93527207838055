import { makeStyles, withStyles, Tooltip } from "@material-ui/core";

/* HD TEMPLATE COLORS */
const backgroundColor = "#fff";
const softGray = "#eee";
const black35 = "#353535";
const acidWashGray = "#e0e4eb";
const hdOrange = "#f96302";
const deepHDOrange = "#E05A01";
const b1Blue = "#b1bace";
const deepB1Blue = "#9fa7b9";
const errorRed = "#cf2a27";
const focusBlue = "#9fc5f8";
const disabledGray = "#696969";
const jeanBlue = "#556689";
const deepJeanBlue = "#4C5B7B"; /*one shade darker than jeanBlue*/
const gridDataTextColor = "#000";

export const hddsStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  pagePaper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "16px",
    display: "flex",
    flexDirection: "column",
  },
  pageHeader: {
    padding: "10px 0",
  },
  headerDivider: {
    marginBottom: "10px",
    height: " 3px",
    backgroundColor: "#f96302",
  },
  toolbar: {
    background: jeanBlue,
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  title: {
    flexGrow: 1,
  },
  text: {
    color: black35,
    fontSize: "1rem",
    margin: "8px",
  },
  sectionDivider: {
    marginBottom: "8px",
  },
  drawerBottom: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  appBarSpacer: theme.mixins.toolbar,
  card: {
    marginTop: "64px",
    padding: "10px",
  },
  contentWorkspace: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    background: acidWashGray,
  },
  container: {
    padding: "24px",
    paddingBottom: theme.spacing(4),
    margin: "auto",
    "@media (min-width: 1024px)": {
      width: "1024px",
    },
  },
  containerLarge: {
    padding: "24px",
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    background: backgroundColor,
  },
  fixedHeight: {
    height: 240,
  },
  breadCrumbs: {
    padding: "10px",
    background: softGray,
    fontSize: "0.75rem",
  },
  breadCrumbsText: {
    fontSize: "0.75rem",
  },
  italicMessage: {
    fontSize: "12px",
    color: "#f44336",
    fontStyle: "italic",
    textAlign: "right",
    marginBottom: ".5em",
  },
  confirmationButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: hdOrange,
    cursor: "pointer",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    border: "1px " + black35 + " solid",
    borderRadius: "4px",
    padding: "0 5px",
    float: "right",
    "&:hover": {
      backgroundColor: deepHDOrange,
    },
    "&:disabled": {
      backgroundColor: deepHDOrange,
    },
    "&:focus": {
      border: "1.5px " + focusBlue + " solid",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  cancelButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: jeanBlue,
    cursor: "pointer",
    color: "#fff",
    fontSize: "1rem",
    fontWeight: "bold",
    border: "1px " + black35 + " solid",
    borderRadius: "4px",
    padding: "0 5px",
    float: "right",
    "&:hover": {
      backgroundColor: deepJeanBlue,
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "&:focus": {
      border: "1.5px " + focusBlue + " solid",
    },
  },
  normalButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    textTransform: "capitalize",
    backgroundColor: b1Blue,
    cursor: "pointer",
    color: black35,
    fontSize: "1rem",
    fontWeight: "bold",
    border: "1px " + black35 + " solid",
    borderRadius: "4px",
    padding: "0 5px",
    "&:hover": {
      backgroundColor: deepB1Blue,
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
    "&:focus": {
      border: "1.5px " + focusBlue + " solid",
    },
  },

  buttonSpacer: {
    width: "16px",
    height: "auto",
    display: "inline-block",
  },

  dialogButton: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },

  dialogPadding: {
    paddingTop: "1.5rem",
  },

  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  pageTitleText: {
    fontSize: "2rem",
    fontVariant: "small-caps",
    color: black35,
    marginTop: "-14px",
  },
  pageSectionText: {
    fontSize: "1rem",
    color: black35,
  },
  pageSectionTitle: {
    fontSize: "1.5rem",
    color: black35,
  },
  loginFooterText: {
    color: black35,
    fontSize: ".9rem",
  },

  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
    },
    "& input.MuiOutlinedInput-input": {
      padding: "7px 7px 7px 7px",
    },
  },

  helpMenu: {
    "& .MuiListItem-button:hover": {
      backgroundColor: b1Blue,
    },
    "& .Mui-selected": {
      backgroundColor: focusBlue,
    },
  },

  normalFormField: {
    "& label.Mui-focused": {
      color: black35,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: black35,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: black35,
      },
      "&:hover fieldset": {
        borderColor: black35,
      },
      "&.Mui-focused fieldset": {
        borderColor: focusBlue,
      },
    },
  },

  errorFormField: {
    "& label.Mui-focused": {
      color: black35,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: errorRed,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: errorRed,
      },
      "&:hover fieldset": {
        borderColor: errorRed,
      },
      "&.Mui-focused fieldset": {
        borderColor: focusBlue,
      },
    },
    "& .MuiFormHelperText-root": {
      color: errorRed,
    },
  },

  requiredFormField: {
    "& label.Mui-focused": {
      color: black35,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: hdOrange,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: black35,
        borderColor: hdOrange,
      },
      "&:hover fieldset": {
        color: black35,
        borderColor: hdOrange,
      },
      "&.Mui-focused fieldset": {
        color: black35,
        borderColor: focusBlue,
      },
    },
  },

  disabledFormField: {
    "& label.Mui-focused": {
      color: disabledGray,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: disabledGray,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: disabledGray,
      },
      "&:hover fieldset": {
        borderColor: disabledGray,
      },
      "&.Mui-focused fieldset": {
        borderColor: disabledGray,
      },
    },
  },
  comboBoxFormControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    "& label.MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& label.MuiInputLabel-outlined": {
      transform: "translate(10px, 10px) scale(1)",
    },
    "& select.MuiOutlinedInput-input": {
      padding: "7px 7px 7px 7px",
    },
    "& div.MuiOutlinedInput-input": {
      padding: "7px 7px 7px 7px",
    },
  },

  checkboxControl: {
    marginLeft: "0px",
    marginBottom: "8px;",
    "& span.MuiButtonBase-root": {
      padding: "0px",
    },
    "& span.MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#556689",
    },
    "& .MuiTypography-body1 ": {
      fontSize: ".9rem",
      marginLeft: "1rem",
      color: black35,
    },
  },
  gridCheckbox: {
    margin: "0 auto",
    "& span.MuiButtonBase-root": {
      padding: "0px",
    },
    "& span.MuiIconButton-label, MuiCheckbox-colorSecondary.Mui-checked": {
      color: "#556689",
    },
  },
  gridRadio: {
    "& span.MuiIconButton-label": {
      marginLeft: "12px",
    },
    "& span.MuiIconButton-label, MuiRadio-colorSecondary.Mui-checked": {
      color: "#556689",
    },
  },
  radioButtonControl: {
    "& label.MuiFormControlLabel-root": {
      marginLeft: "0px",
      marginBottom: "8px;",
    },
    "& span.MuiButtonBase-root": {
      padding: "0px",
    },
    "& span.MuiRadio-colorSecondary.Mui-checked": {
      color: "#556689",
    },
  },

  fieldSetControl: {
    padding: "10px",
  },

  normalFieldSet: {
    border: "1px solid " + black35,
    borderRadius: "4px",
    margin: "8px",
  },
  requiredFieldSet: {
    border: "1px solid " + hdOrange,
    borderRadius: "4px",
    margin: "8px",
  },
  errorFieldSet: {
    border: "1px solid " + errorRed,
    borderRadius: "4px",
    margin: "8px",
  },
  disabledFieldSet: {
    border: "1px solid " + disabledGray,
    borderRadius: "4px",
    margin: "8px",
  },
  headerDividerStyle: {
    border: "1px " + hdOrange + " solid",
  },
  verticalAlignMiddle: {
    alignItems: "center",
    display: "flex",
  },
  fullWidthForm: {
    width: "100%",
  },
  formHelperTextError: {
    color: errorRed,
    marginLeft: "1em",
    marginBottom: "8px",
  },
  formGridRequired: {
    borderColor: hdOrange,
  },
  formGridRequiredError: {
    borderColor: errorRed,
  },
  formHelperTextFormLabel: {
    margin: ".5em 0 0 1em;",
  },
  checkBoxLabel: {
    fontSize: ".9rem",
  },
  panelDefault: {
    backgroundColor: "#556689",
    color: "red",
    minHeight: "100px",
    marginTop: "5%",
    borderRadius: "29px",
    border: "2px solid",
    padding: "12px",
    textAlign: "center",
  },
  panel: {
    "& .MuiContainer-root": {
      padding: 0,
      "& .MuiGrid-item": {
        justifyContent: "center",
      },
      "& .MuiCard-root": {
        width: "500px",
      },
    },
  },
  tableControls: {
    fontSize: "1em",
    "& .MuiTablePagination-caption": {
      fontSize: "1em",
    },
    "& .MuiTableCell-root": {
      fontSize: "1em",
    },
  },
  buttonProgress: {
    color: "white",
    position: "absolute",
  },
  footer: {
    marginTop: "auto",
    height: "2rem",
    paddingBottom: "2rem",
  },
  kGridWithEdit: {
    "& .k-grid-table tr > td:first-child": {
      color: gridDataTextColor,
      fontWeight: "bold",
      backgroundColor: b1Blue,
      borderBottom: "1px" + "solid" + backgroundColor,
      borderRight: "1px" + "solid" + backgroundColor,
      textAlign: "center",
    },
  },
  kGridWithDelete: {
    "& .k-grid-table tr > td:last-Child": {
      color: black35,
      fontWeight: "bold",
      backgroundColor: b1Blue,
      borderBottom: "1px" + "solid" + backgroundColor,
      borderRight: "1px" + "solid" + backgroundColor,
    },
  },
  disabledInfoText: {
    color: disabledGray,
  },
  spinnerSecondary: {
    color: deepHDOrange,
    zIndex: theme.zIndex.drawer + 2100,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 2000,
    color: "#fff",
  },
  outerWrapper: {
    position: "relative",
    minHeight: "100vh",
  },
  innerWrapper: {
    paddingBottom: "2rem",
  },
  warningDialogTitle: {
    paddingBottom: 0,
    color: black35,
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  centeredText: {
    textAlign: "center",
  },
  logo: {
    width: "120px",
    height: "60px",
    cursor: "pointer",
  },
  loginErrorText: {
    color: errorRed,
    fontSize: "18px",
  },
  subscriptionMessage: {
    color: errorRed,
    fontSize: ".9rem",
    marginTop: "8px",
    marginLeft: "8px",
  },
  titleGrid: {
    paddingBottom: 0,
  },
}));
export const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);
