import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const ServiceHistoryLinkReport = (_props) => {
  const globalClasses = globalStyle();

  return (
    <div>
      <PageHeader page={"shl_report"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Service History-Link Report
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <div>
                  <p>
                    Service History data displays in report format, with the
                    most recent service work at the top. Data comes from
                    certified Harley-Davidson service centers who participate in
                    the program. It displays service history data from 2012
                    forward for a valid VIN. The data represented will show work
                    orders for up to the previous day. It will not show
                    today&#39;s data. The report may be saved or printed as
                    desired.
                  </p>
                  <div
                    style={{
                      backgroundColor: "#eef6fc",
                      borderRadius: "4px",
                      padding: "1.25em 1.5em",
                      borderColor: "#3298dc",
                      borderStyle: "solid",
                      borderWidth: "0 0 0 4px",
                    }}
                  >
                    The Service History-Link report is meant for Dealership use,
                    it is not intended to be consumed by the customer.
                  </div>
                  <p>A sample of the report is below:</p>
                  <p>
                    <img
                      src="assets/images/shl report sample.png"
                      style={{ border: "1px solid black" }}
                    />
                  </p>
                  <p>
                    Events display the participating dealer&#39;s actual work
                    order event/job number, unless the work order only has a
                    charge (no events are found- such as storage/shop
                    supplies/towing/etc.); in that case, one event will be
                    displayed that says ‘Service chargeable event&#39;.
                    Non-participating dealers will only display the number of
                    events found. Not all valid TALONes events are sent to HDMC
                    (when no labor, parts, or non-inventory is on the event).
                  </p>
                  <h2>Report Header</h2>
                  <p>
                    The top of the report contains the date and time the report
                    was run and the vehicle information. 
                  </p>
                  <p>
                    <img
                      height="85"
                      src="assets/images/header.png"
                      style={{ border: "1px solid black" }}
                      width="600"
                    />
                  </p>
                  <table
                    style={{
                      width: "100%",
                      captionSide: "bottom",
                      borderCollapse: "collapse",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "167px" }} />
                      <col style={{ width: "650px" }} />
                    </colgroup>
                    <tbody>
                      <tr style={{ backgroundColor: "#556689", color: "#fff" }}>
                        <td style={{ padding: "5px 0px 5px 10px" }}>Field</td>
                        <td>Description</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Results Date
                        </td>
                        <td>Date the report was retrieved</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Results Time
                        </td>
                        <td>
                          Time results were retrieved (Central Standard Time)
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          VIN
                        </td>
                        <td>Vehicle Identification Number entered</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Make / Model / Year
                        </td>
                        <td>
                          Vehicle manufacturer / vehicle model code / year
                          (Note: it is possible for information to be missing if
                          not found in the data)
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Color
                        </td>
                        <td>
                          Vehicle color(s) (Note: it is possible for information
                          to be missing if not found in the data)
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <h2>Service Entry</h2>
                  <p>
                    Each service entry is denoted with a light orange header
                    with the date of service, vehicle mileage in, Dealer and
                    work order number. Below that that each service event,
                    description and parts (if applicable) is listed.
                  </p>
                  <p>
                    <img src="assets/images/service entry detail.png" />
                  </p>
                  <table
                    style={{
                      width: "100%",
                      captionSide: "bottom",
                      borderCollapse: "collapse",
                    }}
                  >
                    <colgroup>
                      <col style={{ width: "167px" }} />
                      <col style={{ width: "650px" }} />
                    </colgroup>
                    <tbody>
                      <tr style={{ backgroundColor: "#556689", color: "#fff" }}>
                        <td style={{ padding: "5px 0px 5px 10px" }}>Field</td>
                        <td>Description</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Date
                        </td>
                        <td>The invoice date of the work order</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Mileage
                        </td>
                        <td>Work order / repair order mileage in</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Dealer
                        </td>
                        <td>
                          Dealer number and name (if a participating
                          Harley-Davidson authorized service center). For
                          non-participating dealers it will display
                          &#39;Non-Participating Service History-Link
                          Dealer&#39;
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Work Order
                        </td>
                        <td>Work order number / repair order number</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Event/Job
                        </td>
                        <td>
                          This number is assigned by the Dealer Management
                          System.
                          <p>
                            Events display the participating dealer&#39;s actual
                            work order event/job number, unlessthe work order
                            only has a charge (no events are found- such as
                            storage/shop supplies/towing/etc.); in that case,
                            one event will be displayed that says ‘Service
                            chargeable event&#39;. Non-participating dealers
                            will only display the number of events found. Not
                            all valid TALONes events are sent to HDMC (when no
                            labor, parts, or non-inventory is on the event).
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Description
                        </td>
                        <td>Work order event description</td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Pay
                        </td>
                        <td>
                          <p>
                            Cust = if the customer paid for the event when the
                            work order was closed
                            <br />
                            Other = service work paid by someone other than the
                            customer (Warranty, ESP, PPM, Policy, etc.)
                            <br />
                            (Note: Internal work orders / repair orders will
                            always be &#39;Other&#39; since the work
                            <br />
                            is billed to the Vehicle department.)
                          </p>
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Item #
                        </td>
                        <td>
                          Harley-Davidson part number (displays &#39;n/a&#39;
                          for non-Harley-Davidson parts)
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Item Description
                        </td>
                        <td>
                          <p>
                            Part description (if the description is not
                            available, the report will display blank).
                          </p>
                          <p>For non-HD parts:</p>
                          <ul class="Square">
                            <li>
                              The description will display &#39;n/a&#39; for
                              work orders completed before March 8, 2021.
                              <br />
                              <img
                                height="147"
                                src="assets/images/old non hd part description.png"
                                width="500"
                              />
                            </li>
                            <li>
                              The part description will display for work orders
                              completed March 8, 2021 or later.
                              <br />
                              <img
                                height="169"
                                src="assets/images/new non hd part description.png"
                                width="500"
                              />
                            </li>
                          </ul>
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Vendor
                        </td>
                        <td>
                          Vendor code (&#39;HD&#39;) (displays &#39;n/a&#39; for
                          non-Harley-Davidson parts)
                        </td>
                      </tr>
                      <tr style={{ borderBottom: "1px solid lightgray" }}>
                        <td
                          style={{
                            fontWeight: "bold",
                            padding: "5px 0px 5px 10px",
                            verticalAlign: "top",
                          }}
                        >
                          Qty
                        </td>
                        <td>Amount of items used</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceHistoryLinkReport;
