import React from "react";
import { CallbackComponent } from "redux-oidc";
import userManager from "../../utils/authUtil";
import { useDispatch } from "react-redux";
import { loginFailed } from "../../action/loginAction";
import { Redirect } from "react-router-dom";
import {
  LoginResponseMessages,
  LoginHelperText,
} from "../../enums/loginmessages";
import PageHeader from "../pageheader/PageHeader";
import { CssBaseline, Typography } from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const Callback = () => {
  const dispatch = useDispatch();
  const globalClasses = globalStyle();

  const [status, setStatus] = React.useState(0);

  const handleErrorMessage = (error: string) => {
    const errorMessage = error.toString().split(":")[1].trim().toLowerCase();

    switch (errorMessage) {
      case LoginResponseMessages.USER_LAST_ATTEMPT:
        return <Typography>{LoginHelperText.LOCK_WARNING_TEXT}</Typography>;
      case LoginResponseMessages.USER_LOCKED:
      case LoginResponseMessages.USER_IS_LOCKING:
        return (
          <Typography className={globalClasses.loginErrorText}>
            {LoginHelperText.USER_LOCKED_TEXT}
          </Typography>
        );
      default:
        return (
          <Typography className={globalClasses.loginErrorText}>
            {LoginHelperText.DEFAULT_MESSAGE}
          </Typography>
        );
    }
  };

  return (
    <>
      <PageHeader showUser={false} />
      <CssBaseline />
      <CallbackComponent
        userManager={userManager}
        successCallback={() => setStatus(1)}
        errorCallback={(error) => {
          localStorage.removeItem("dp_userDetails");
          localStorage.removeItem("dp_user_id_token");
          setStatus(2);
          dispatch(loginFailed(handleErrorMessage(error)));
        }}
      >
        {status === 0 ? (
          <div>Redirecting...</div>
        ) : status === 1 ? (
          <Redirect to="/" />
        ) : (
          <Redirect to="/welcome" />
        )}
      </CallbackComponent>
    </>
  );
};

export default Callback;
