import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";
import ForgotPasswordReminder from "./forgot-password-reminder";

const globalStyle = hddsStyles;

const ServiceHistoryLink = (_props) => {
  const globalClasses = globalStyle();
  return (
    <div>
      <PageHeader />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Service History-Link
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                For help using the Service History-Link report and managing your
                subscriptions, select a topic below.
              </Grid>
              <Grid item xs={12}>
                <Typography className={globalClasses.pageSectionTitle}>
                  Contents
                </Typography>
                <ul
                  style={{
                    listStyleType: "none",
                    lineHeight: "30px",
                    paddingLeft: "20px",
                  }}
                >
                  <li>
                    <a href="/help/Logging_In">Logging In</a>
                  </li>
                  <li>
                    <a href="/help/Generating_a_Report">Generating a Report</a>
                  </li>
                  <li>
                    <a href="/help/Service_History-Link_Report">
                      Service History-Link Report
                    </a>
                  </li>
                  <li>
                    <a href="/help/CPO_Interpretation">CPO Interpretation</a>
                  </li>
                  <li>
                    <a href="/help/Managing_Your_Subscription">
                      Managing Your Subscription
                    </a>
                  </li>
                  <li>
                    <a href="/help/Adding_Users">Adding and Editing Users</a>
                  </li>
                </ul>
              </Grid>
              <ForgotPasswordReminder />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ServiceHistoryLink;
