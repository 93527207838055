import * as types from "../actionTypes/toastActionTypes";

const toastsInitialState = {
  isOpen: false,
  message: "",
  type: "",
};

export default (state = toastsInitialState, action) => {
  switch (action.type) {
    case types.SHOW_TOAST_MESSAGE:
      return {
        ...state,
        message: action.payload?.message,
        type: action.payload?.status,
        isOpen: true,
      };
    case types.HIDE_TOAST_MESSAGE:
      return { ...state, isOpen: false, message: "", type: "" };
    default:
      return state;
  }
};
