import React from "react";
import Paper from "@material-ui/core/Paper";
import PageHeader from "../pageheader/HelpPageHeader";
import {
  Grid,
  Typography,
  Divider,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { hddsStyles } from "../../core/styles/styles";

const globalStyle = hddsStyles;

const ManagingYourSubscription = (_props) => {
  const globalClasses = globalStyle();

  return (
    <div>
      <PageHeader page={"manage_sub"} />
      <CssBaseline />
      <Container maxWidth="md" className={globalClasses.container}>
        <div className={globalClasses.appBarSpacer} />
        <Grid container spacing={2}>
          <Grid item xs={12} className={globalClasses.titleGrid}>
            <Typography className={globalClasses.pageTitleText}>
              Managing Your Subscription
            </Typography>
            <Divider className={globalClasses.headerDividerStyle} />
          </Grid>
          <Grid item xs={12}>
            <Paper className={globalClasses.paper}>
              <Grid item xs={12} style={{ paddingBottom: "10px" }}>
                <h2>Manage Users</h2>
                <p>
                  The top grid contains a list of all users of the SHL
                  subscription. From here you can edit or add users to your
                  organization.
                </p>
                <p>
                  <img src="assets/images/manage_users_users.png" />
                </p>
                <p>
                  To edit a user, select the edit icon
                  <img src="assets/images/edit icon.png" /> next the user. To
                  remove a user, select the delete icon
                  <img src="assets/images/delete_icon.png" /> and confirm in the
                  pop-up box.
                </p>
                <table
                  style={{
                    width: "100%",
                    captionSide: "bottom",
                    borderCollapse: "collapse",
                  }}
                >
                  <colgroup>
                    <col style={{ width: "167px" }} />
                    <col style={{ width: "650px" }} />
                  </colgroup>
                  <tbody>
                    <tr style={{ backgroundColor: "#556689", color: "#fff" }}>
                      <td style={{ padding: "5px 0px 5px 10px" }}>Field</td>
                      <td>Description</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Edit Icon
                      </td>
                      <td>
                        Select the icon to edit user details, set subscription
                        access, or change a password.
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Delete Icon
                      </td>
                      <td>Select the icon to remove the user.</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Employee
                      </td>
                      <td>Employee&#39;s full name</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Username
                      </td>
                      <td>Username for logging in</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Admin
                      </td>
                      <td>
                        &quot;Y&quot; indicates user is set as an admin and can
                        modify other employees.
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Default Store
                      </td>
                      <td>The users default store.</td>
                    </tr>
                  </tbody>
                </table>
                <h2>Subscription Details</h2>
                <p>
                  The bottom grid contains a list of the organization
                  subscriptions with details for each the subscription for each
                  store.
                </p>
                <p>
                  <img
                    src="assets/images/manage_users_subscriptions.png"
                    style={{ border: "1px solid black" }}
                  />
                </p>
                <table
                  style={{
                    width: "100%",
                    captionSide: "bottom",
                    borderCollapse: "collapse",
                  }}
                >
                  <colgroup>
                    <col style={{ width: "167px" }} />
                    <col style={{ width: "650px" }} />
                  </colgroup>
                  <tbody>
                    <tr style={{ backgroundColor: "#556689", color: "#fff" }}>
                      <td style={{ padding: "5px 0px 5px 10px" }}>Field</td>
                      <td>Description</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Store
                      </td>
                      <td>A store in an organization.</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Subscription
                      </td>
                      <td>Subscription level.</td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        Max # of Users Allowed
                      </td>
                      <td>
                        Maximum users permitted under the stores subscription
                        teir.
                      </td>
                    </tr>
                    <tr style={{ borderBottom: "1px solid lightgray" }}>
                      <td
                        style={{
                          fontWeight: "bold",
                          padding: "5px 0px 5px 10px",
                          verticalAlign: "top",
                        }}
                      >
                        # of Users Assigned
                      </td>
                      <td>
                        The number of users currently assigned to that
                        store&#39;s subscription.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default ManagingYourSubscription;
